import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 100px;
  width: 100%;
`;

export const Content = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 20px;
`

export const InputText = styled.input`
  width: 50%;
  height: 25px;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  padding: 5px;
  border: none;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  
`;

export const SearchButton = styled.button`
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 90px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border-width: 1px;
  border-style: none;

  &:hover {
    cursor:  pointer;
  }
`;

export const SearchTitle = styled.h1`
  padding-bottom: 10px;
  margin-top: 100px;
  font-family: "axis";
  color: white;
`