import styled, { keyframes } from 'styled-components';


const ShowThePost = keyframes`
  0%   { width: 65%; background-color: rgba(255, 255, 255, 0); box-shadow: 0 0 0em rgba(0, 0, 0, 0.2); padding-top: 0px;}
  100% {  width: 85%; background-color: rgba(255, 255, 255, 255); box-shadow: 0 0 2em rgba(0, 0, 0, 0.2); padding-top: 100px;}
`

const HideThePost = keyframes`
  0% { width: 85%; background-color: rgba(255, 255, 255, 255); box-shadow: 0 0 2em rgba(0, 0, 0, 0.2); padding-top: 100px;}
  100%   { width: 65%; background-color: rgba(255, 255, 255, 0); box-shadow: 0 0 0em rgba(0, 0, 0, 0.2); padding-top: 0px;}
`
const ShowBlogText = keyframes`
0% { opacity: 0; }
100% { opacity: 1;}
`
const HidelogText = keyframes`
0% { opacity: 1; }
100% { opacity: 0;}
`

const VanishHidedButton = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0;}
`

const showHidedButton = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1;}
`

export const Container = styled.div`
  width: 65%;
  display: flex;
  animation-name: ${props => props.expand ? ShowThePost : HideThePost};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  border: none;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: 40px;
`;

export const Content = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: ${props => props.expand ? "auto" : "pointer" };;
  }
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const BlogPostText = styled.p`
  display: ${props => props.expand ? "flex" : "none"};
  color: black;
  font-size: 15px;
  margin-top: 30px;
  text-align: justify;
  background-color: transparent;
  padding-left: 320px;
  padding-right: 320px;
  padding-bottom: 90px;
  animation-name: ${props => props.expand ? ShowBlogText : HidelogText};
  animation-duration: 1s;
  animation-fill-mode: forwards;

  @media (max-width: 1280px) {
    margin-top: 10px;
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 70px;
  }
`

export const HideBlogPostText = styled.div`
  position: absolute;
  /* width: 85%; */
  background-color: transparent;
  top: 0;
  display: flex;
  justify-content: flex-end;
  padding: 50px 10px 10px 10px;
`

export const HideBlogPostButton = styled.button`
  border: none;
  background-color: transparent;
  animation-name: ${props => props.expand ? showHidedButton : VanishHidedButton};
  animation-duration: 1s;
  animation-fill-mode: forwards;

  &:hover {
    cursor: pointer;
  }
`


