import React, { Component } from 'react';
import "./styles.css";
import Footer from '../../components/Footer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  ChangeLanguageAction, 
  ChangeHeaderColorAction, 
  FooterColorAction,
  ChangeLanguageFeedbackAction  
} from '../../actions';
import Award_img from "../../img/awards.png";
import api from '../../services/api';
import { payload } from '../../services/payload';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import Samsung_creative from "../../img/samsung-creative.png";
import cartier_award from "../../img/cartier-award.png";
import Epilepsy_foudation from "../../img/epilepsy-foudation-award.png";
import sirio_libanes from "../../img/Sirio-libanes.png";
import pfizer from "../../img/pfizer.png";
import Logo_berrini from "../../img/Logo-berrini.png";
import fmusp from "../../img/fmusp-logo.png";
import fundacao_everis from "../../img/fundacao-everis.png";
import selo_finep_mulheres from "../../img/selo-finep-mulheres.png";

const english_video = "https://www.youtube.com/embed/YrCbpJc6S_M";
const portuguese_video = "https://www.youtube.com/embed/jtJ_e5N2w4o";

class About extends Component {
  componentDidMount() {

    window.scrollTo(0, 0);
    this.props.FooterColorAction(false);
    this.props.ChangeHeaderColorAction(7);


    const { language } = this.props.reducer.Language;
    this.setState({  
      subject: language.about_page.options.info
    });
    
    ReactGA.pageview("/about");
  }
  constructor (props) {
    super(props);
    this.state = { 
      loading: false,
      Main_image_url: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+sobre/Epistemic-About-Initial.png",
    };
  }
  
  render() {
    const { language } = this.props.reducer.Language;
    const { show } = this.props.reducer.show_language_feedback;
    const { Main_image_url } = this.state;
    
    return (
      <div className="main_page_about" 
        onClick={() => {
          try {
            if (show) {
              document.querySelector(".list-options").setAttribute("style", "animation: diminuindo 0.5s");
              setTimeout(() => {
                this.props.ChangeLanguageFeedbackAction(false);
              }, 400)
            }
          }catch(e) {
            console.log(e);
          }
        }}
      >

        <Helmet>
          <title>Epistemic - {language.header.about.toLowerCase()}</title>
        </Helmet>

        <div className="Front-image-div" >
            {/* <div className="About-us-div" > */}
              <img loading='lazy' className="Products-img"  src={Main_image_url} alt={"Epistemic_Image"} />
              <p className="About-us-text" >
                {language.about_page.main_page_title_1} <br /> 
                {language.about_page.main_page_title_2} <br /> 
                {language.about_page.main_page_title_3} <br /> 
                {language.about_page.main_page_title_4} 
              </p>
            {/* </div> */}
        </div>

        <div className="div_texts">
          <iframe
            className="video-iframe"
            allowfullscreen="allowfullscreen" 
            mozallowfullscreen="mozallowfullscreen" 
            msallowfullscreen="msallowfullscreen" 
            oallowfullscreen="oallowfullscreen" 
            webkitallowfullscreen="webkitallowfullscreen"
            frameborder={5}
            src={language.language === "pt_BR" ? portuguese_video : english_video}>
          </iframe>

        </div>

        <div className="div-row-history" >

          
          <div className="Aling-first-text-div" >
            <p className="Epistemic-text-title" >{language.about_page.epistemic}</p>
            <br/>
              <img 
                className="Aurora-Image" 
                src={"https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+sobre/Sobre.png"} 
                alt={"Aurora"} 
              />
            <p className="Epistemic-text-explanation" >
              {language.about_page.text.line_1} <br/><br/>
              {language.about_page.text.line_2} <br/><br/>
              {language.about_page.text.line_3} <br/><br/>
              {language.about_page.text.line_4} 
            </p>
          </div>

          {/* <div className="Aling-first-image-div" >
            <p className="Epistemic-text-title" >Nossas novidades</p>
            <br/>

            <Link 
              to={"/aurora"}>
              <img 
                className="Aurora-Image" 
                src={"https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+sobre/Sobre.png"} 
                alt={"Aurora"} 
              />
            </Link>
          </div> */}
        </div>  

        <div className="Aling-award-text-div">
          <p className="Epistemic-text-title" >{language.about_page.our_awards} </p>
        </div>

        <div className='awards_div' >
          <div className="award-main-div">
            <img src={Award_img} className="Award-img" />
            <p className="award-title">{language.about_page.finep_women}</p>
            <p className="award-date">2021</p>
            <img src={selo_finep_mulheres} className="Award-img" />
          </div>

          <div className="award-main-div">
            <img src={Award_img} className="Award-img" />
            <p className="award-title">Participação no programa Creative Startups da Samsung</p>
            <p className="award-date">2019</p>
            <img src={Samsung_creative} className="Award-img" />
          </div>

          <div className="award-main-div">
            <img src={Award_img} className="Award-img" />
            <p className="award-title">Cartier Women's Initiative Awards</p>
            <p className="award-date">2018</p>
            <img src={cartier_award} className="Award-img-cartier" />
          </div>
        </div>

        <div className='awards_div'>
          <div className="award-main-div">
            <img src={Award_img} className="Award-img" />
            <p className="award-title">Shark Tank <br/> Epilepsy Foundation of America</p>
            <p className="award-date">2017</p>
            <img src={Epilepsy_foudation} className="Award-img-epilepsy" />
          </div>

          <div className="award-main-div">
            <img src={Award_img} className="Award-img" />
            <p className="award-title">Demo Day Berrini Ventures  eHealth Summit <br/> FMUSP</p>
            <p className="award-date">2016</p>
            <img src={Logo_berrini} className="Award-img-demo-day" />
            <img src={fmusp} className="Award-img-demo-day" />
          </div>


          <div className="award-main-div">
            <img src={Award_img} className="Award-img" />
            <p className="award-title">Empreenda Saúde: <br/> Sírio Libanês e Fundação Everis</p>
            <p className="award-date">2015</p>
            <img src={sirio_libanes} className="Award-img-sirio-libanes" />
            <img src={fundacao_everis} className="Award-img-sirio-libanes" />
          </div>
        </div>

        <div className='awards_div'>
          <div className="award-main-div">
            <img src={Award_img} className="Award-img" />
            <p className="award-title">Desafio Pfizer Inovação <br/> Pfizer e Berrini Ventures</p>
            <p className="award-date">2015</p>
            <img src={pfizer} className="Award-img-pfizer" />
            <img src={Logo_berrini} className="Award-img-demo-day" />
          </div>
        </div>

        <div className="Div-Footer">
          <Footer black={false} />
        </div>
      
      </div>
    );
  }
}

const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    ChangeLanguageAction, 
    ChangeHeaderColorAction, 
    FooterColorAction,
    ChangeLanguageFeedbackAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(About);