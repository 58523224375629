const payload = {
  routes: {
    login: {
      action: "login",
      module: "author"
    },
    delete_post: {
      action: "delete_post",
      module: "author"
    },
    schedule_post: {
      action: "schedule_post",
      module: "author"
    },
    Send_BlogPost: {
      action: "create_post",
      module: "author"
    },
    Update_BlogPost: {
      action: "update_blog_post",
      module: "author"
    },
    Get_all_blogPosts: {
      action: "get_all_posts",
    },
  },
  endpoints: {
    user: "epistemic-backend-management-blog-news",
    send_feedback: "send-email-website"
  }
}

export { payload };