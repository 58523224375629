import React, { useState, useEffect } from 'react';
import { Container, 
  LoadingMainDiv, 
  FirtstInfosDiv, 
  TitleInput, 
  TagButton, 
  SecondInfosDiv, 
  TagButtonGroup, 
  PostOptions,
  PostSection,
  PostOptionTitle,
  PostDateInput,
  CheckBoxEndPost,
  CheckBoxPostDiv,
  CheckBoxEndPostLegend,
  PostImageInput,
  ImagePreview,
  EditorDiv,
  ButtonOptions,
  ActionButton,
  PostMainColor
} from './styles';
import {Link, useLocation} from "react-router-dom";
import ReactLoading from 'react-loading';
import moment from 'moment';
import { stateToHTML } from 'draft-js-export-html';
import api from '../../services/api';
import { payload } from '../../services/payload';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { EditorState, ContentState, convertFromHTML, CompositeDecorator } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './EditorStyles.css';

const imageUrlDefault = "https://toolstud.io/ezoimgfmt/img.toolstud.io/240x135/3b5998/fff&text=+1920x1080+?ezimgfmt=rs:240x135/rscb2/ng:webp/ngcb2";

export default function CreateAndEditBlogPost({ props }) {

  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(true);
  const [life, setLifeTAg] = useState(false);
  const [epilepsy, setEpilepsyTAg] = useState(false);
  const [news, setNewsTAg] = useState(false);
  const [tec, setTecTAg] = useState(false);
  const [health, setHealthTAg] = useState(false);
  const [dateToPost, setDateToPost] = useState(moment().format("YYYY-MM-DD"));
  const [dateToEndPost, setDateToEndPost] = useState("");
  const [dateToEndCheckBox, setDateToEndCheckBox] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [PostTitle, setPostTitle] = useState("");
  const [EditPost, setEditPost] = useState(false);
  const [MainColor, setMainColor] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  let location = useLocation();

  useEffect(() => {

    // const getStoragedPost = JSON.parse(localStorage.getItem("post"));

    // console.log(getStoragedPost.title);
    // if(location.state) {

    // }

    // if(getStoragedPost) {
    //   if(getStoragedPost.title === location.state.title){
    //     setPost(getStoragedPost);
    //   }

    if(location.state) {
      const { title, image, post_end_on, text, post_on, tags, color } = location.state;
      console.log(location.state);
      setPost(location.state);
      setLoading(false);
      setPostTitle(title);
      setImageUrl(image);
      setDateToPost(post_on);
      setDateToEndCheckBox(post_end_on === null ? true : false);
      setMainColor(color);
      ShowTags(tags);

      const decorator = new CompositeDecorator([
        {
          strategy: findLinkEntities,
          component: Link,
        },
        {
          strategy: findImageEntities,
          component: Image,
        },
      ]);
      const blocksFromHTML = convertFromHTML(text);;
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );

      setEditorState(EditorState.createWithContent(state, decorator,));

      setEditPost(true);
    } else {
      setEditPost(false);
    }



    // window.alert("Algo deu errado");

  }, [location, location.state]);

  // if(loading) {
  //   return (
  //     <LoadingMainDiv>
  //       <ReactLoading type={"spinningBubbles"} color={"#5F2F85"} height={'10%'} width={'10%'} />
  //     </LoadingMainDiv>
  //   )
  // }

  function ShowTags (_tags) {
    let TagsArray = _tags.split(",");
    TagsArray.map(item => {
      switch(item) {
        case "1": setNewsTAg(true); break;
        case "2": setHealthTAg(true); break;
        case "3": setEpilepsyTAg(true); break;
        case "4": setLifeTAg(true); break;
        case "5": setTecTAg(true); break;
      }
    })
  }
  function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        );
      },
      callback
    );
  }
  
  function findImageEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'IMAGE'
        );
      },
      callback
    );
  }

  const Image = (props) => {
    const {
      height,
      src,
      width,
    } = props.contentState.getEntity(props.entityKey).getData();

    return (
      <img src={src} height={height} width={width} />
    );
  };

  return (
    <Container>

      <FirtstInfosDiv>

        <TitleInput 
          value={PostTitle}
          onChange={(event) => setPostTitle(event.target.value)}
          placeholder={"Título aqui..."}
        />
        <EditorDiv>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(event) => setEditorState(event)}
          />
        </EditorDiv>

      </FirtstInfosDiv>

      <SecondInfosDiv>

          <PostOptions>

            <PostSection>
              <PostOptionTitle>Data para publicar</PostOptionTitle>
              <PostDateInput 
                value={dateToPost} 
                type={"date"} 
                min={moment().format("YYYY-MM-DD")}
                onChange={(event) => setDateToPost(event.target.value)}
              />
            </PostSection>

            <PostSection>
              <PostOptionTitle>Data para encerrar a publicação</PostOptionTitle>
              <PostDateInput 
                value={dateToEndPost} 
                type={"date"} 
                disabled={dateToEndCheckBox}
                min={moment().format("YYYY-MM-DD")}
                onChange={(event) => setDateToEndPost(event.target.value)}
              />

              <CheckBoxPostDiv>
                <CheckBoxEndPost 
                  checked={dateToEndCheckBox}
                  onChange={(event) => setDateToEndCheckBox(event.target.checked)} 
                  type="checkbox" 
                />
                <CheckBoxEndPostLegend>
                  Sem data para encerrar
                </CheckBoxEndPostLegend>
              </CheckBoxPostDiv>

            </PostSection>


            <PostSection>
              <PostOptionTitle>Tags</PostOptionTitle>

              <TagButtonGroup>
                <TagButton
                  color={"#4959c4"}
                  onClick={() => {
                    setHealthTAg(!health);
                  }}
                  feedback={health}
                >
                  Saúde 
                </TagButton>
                <TagButton
                  onClick={() => {
                    setEpilepsyTAg(!epilepsy);
                  }}
                  color={"#8a1782"}
                  feedback={epilepsy}
                >
                  Epilepsia
                </TagButton>
                <TagButton
                  onClick={() => {
                    setNewsTAg(!news);
                  }}
                  feedback={news}
                  color={"#d44c4c"}
                >
                  Novidades 
                </TagButton>
                <TagButton
                  onClick={() => {
                    setTecTAg(!tec);
                  }}
                  feedback={tec}
                  color={"#4de4ff"}
                >
                  Tecnologia
                </TagButton>
                <TagButton
                  onClick={() => {
                    setLifeTAg(!life);
                  }}
                  feedback={life}
                  color={"#71d15c"}
                >
                  Vida 
                </TagButton>
              </TagButtonGroup>
            </PostSection>

            <PostSection>

              <PostOptionTitle>Cor principal para o Post </PostOptionTitle>

              <PostMainColor 
                type="color"
                value={MainColor}
                onChange={(event) => setMainColor(event.target.value)}
              />
            </PostSection>
            
            <PostSection>

              <PostOptionTitle>Imagem do card</PostOptionTitle>

              <PostImageInput 
                placeholder="URL da imagem..."
                value={imageUrl}
                onChange={(event) => setImageUrl(event.target.value)}
              />

              <ImagePreview src={imageUrl !== "" ? imageUrl : imageUrlDefault} />

            </PostSection>

            <ButtonOptions>

              <ActionButton
                color="green"
                onClick={() => CreateBlogPost()}
              >
                Concluir
              </ActionButton>

              {EditPost ? (<ActionButton
                onClick={() => {
                  alert("Salvar o post");
                }}
                color='#DF7400'
              >
                Salvar
              </ActionButton>) : <></> }

              {EditPost ? (<ActionButton
                onClick={() => {
                  alert("Deletar");
                }}
                color="red"
              >
                Deletar
              </ActionButton>) : <></>
              
            }

            </ButtonOptions>
            
          

          </PostOptions>

      </SecondInfosDiv>
    </Container>
  );

  async function CreateBlogPost () {
    const response = await api.post( payload.endpoints.user, {
      data: {
        route: payload.routes.Send_BlogPost,
        tags: createTagsNum(),
        image_url: imageUrl,
        author: "Epistemic",
        html: stateToHTML(editorState.getCurrentContent()),
        title: PostTitle,
        post_on: dateToPost,
        actived: true,
        today_date: moment().format("YYYY-MM-DD"),
        post_end_on: dateToEndCheckBox ? null : dateToEndPost,
      }
    });

    if(response.status) {
      // alert("Sucesso ao postar");
      return <Redirect to="/author_home"/>
    }
  }

  function createTagsNum () {
    let tags = "";
    if(health) {
      tags += "2,";
    }
    if(epilepsy) {
      tags+= "3,"
    }
    if(news) {
      tags+= "1,"
    }

    if(tec){
      tags+= "5,"
    }

    if(life){
      tags+= "4,"
    }
    return tags;
  }
}