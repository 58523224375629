import React from 'react';
import { Container, IconButton } from './styles';
import { FaWhatsapp, FaViber, FaTwitter, FaFacebook } from 'react-icons/fa';
import { FacebookShareButton,  TwitterShareButton, WhatsappShareButton, ViberShareButton, } from 'react-share';

export default function ShareIcons({ title, color }){
  return (
    <Container>
    
      <FacebookShareButton
        url={"https://pt-br.reactjs.org/"}
      > 
        <FaFacebook size={30} color={color} />
      </FacebookShareButton>

      <ViberShareButton
        style={{ marginTop: 10 }}
        url={"https://pt-br.reactjs.org/"}
      > 
        <FaViber size={30} color={color} />
      </ViberShareButton>

      <WhatsappShareButton
        style={{ marginTop: 10 }}
        url={"https://pt-br.reactjs.org/"}
      > 
        <FaWhatsapp size={30} color={color} />
      </WhatsappShareButton>
      
      <TwitterShareButton
        style={{ marginTop: 10 }}
        url={"https://pt-br.reactjs.org/"}
      > 
        <FaTwitter size={30} color={color} />
      </TwitterShareButton>
      

    </Container>
  );
}
