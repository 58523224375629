import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: white;
  padding: 20px 20px 40px 20px;
`;

export const HeaderPosts = styled.div`
  display: flex;
  flex-direction: row;
`

export const HeaderTitle = styled.h1`
  font-weight: normal;
  color: black;
`

export const AddPostButton = styled.button`
  margin-left: 20px;
  border-radius: 5px;
  border-style: solid;
  border-color:  #DF7400;
  background-color: transparent;
  font-family: 'axis';
  padding: 5px;
  color: #DF7400;

  &:hover {
    cursor: pointer;
  }
`

export const TablePosts = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
`

export const TrTitle = styled.tr`
  border: 1px solid transparent;
  text-align: left;

`
export const ThTitle = styled.th`
  /* border: 1px solid red;  */
  text-align: left;
  padding: 8px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid black;
  background-color: white;
`
export const ThCheckBox = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  justify-content: center;
`
export const TrRows = styled.tr`
  border: 1px solid transparent;
  text-align: left;

  &:nth-child(even) {
    background-color: #f0f0f0;
  }
`

export const ThRows = styled.th`
  border: 1px solid transparent;
  text-align: left;
  padding: 8px;
  font-size: 15px;
  font-weight: normal;
`

export const CheckBoxPost = styled.input`
  color: #DF7400;
  background-color: #DF7400;
`

export const PostOptions = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const DeletePost = styled.button`
  height: 40px;
  padding: 10px;
  font-family: 'axis';
  background-color: white;
  border-style: solid;
  border-radius: 5px;
  border-color:  #DF7400;
  color: #DF7400;

  &:hover {
    cursor: pointer;
  }
`

export const ThPostName = styled.th`
  border: 1px solid transparent;
  text-align: left;
  padding: 8px;
`
