import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  ChangeLanguageAction, 
  FooterColorAction, 
  ChangeHeaderColorAction, 
  ChangeLanguageFeedbackAction 
} from '../../actions';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Parallax } from 'react-scroll-parallax';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import Main_image_url from '../../svgs/pt-br/home/Main-Image.svg';

import "./styles.css";

class Main extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.FooterColorAction(false);
    this.props.ChangeHeaderColorAction(1);

    ReactGA.pageview("/home");

  }
  constructor(props) {
    super(props);
    this.state = { 
      Main_image_url: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+inicial/Epistemic-Inicial.png",
      App_iamge_url: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+inicial/Epistemic-Inicial-App.png",
      Web_image_url: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+inicial/Epistemic-Inicial-Web.png",
      Web_image_url_2: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+inicial/Epistemic-carousel-2.png",
      Web_image_url_3: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+inicial/Epistemic-carousel-3.png",
      Aurora_image_url: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+sobre/Sobre.png",
      App_premium_url: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+inicial/Epistemic-Inicial-App-Premium.png",
    };
  }
  render() { 
    const { show } = this.props.reducer.show_language_feedback;
    const { language } = this.props.reducer.Language;
    const { 
      Main_image_url, 
      App_iamge_url, 
      Web_image_url, 
      Web_image_url_2,
      Web_image_url_3,
      Aurora_image_url,
      App_premium_url
    } = this.state;

    return (
      <div className="Main-Page"
        onClick={() => {
          try {
            if (show) {
              document.querySelector(".list-options").setAttribute("style", "animation: diminuindo 0.5s");
              setTimeout(() => {
                this.props.ChangeLanguageFeedbackAction(false);
              }, 400)
            }
          }catch(e) {
            console.log(e);
          }
        }}
        onTouchStart={() => {
        document.querySelector(".side_bar").setAttribute("style", "left: -50%;display: grid; animation-name: closeSisde_bar;");

        try {
          if (show) {
            document.querySelector(".list-options").setAttribute("style", "animation: diminuindo 0.5s");
            setTimeout(() => {
              this.props.ChangeLanguageFeedbackAction(false);
            }, 400)
          }
        }catch(e) {
          console.log(e);
        }
      }}>
        <Helmet>
          <title>Epistemic - {language.header.home.toLowerCase()}</title>
        </Helmet>
        <div className="main_image-div">
          
          <div className="main_image_row" >
            <img src={Main_image_url} loading='lazy' alt="Epistemic-Main-Image" className="main_image_home" />

            <div className="main_text_div" >
              <h2 className="main_text_title">
                {language.main_page.your} <br/> {language.main_page.health}, <br/> {language.main_page.our} <br/> {language.main_page.mission}
              </h2>
            </div>
          </div>


        <div className="Div-gradient" >
          <h2 className="Second-text-title" >
            {language.main_page.our_solutions}
          </h2>

          <p className="Second-text-explanation" >
            {language.main_page.welcome} <b> {language.main_page.welcome_bold} </b>
          </p>
        </div>
          
        <div className="Image-App-div" >
          <Parallax className="custom-class" y={[-10, 2]} tagOuter="figure">
            <img className="Image-App-sides" src={App_iamge_url} alt={"App_left"} /> 
          </Parallax>
        </div>

        </div>

        <div className="Third-app-main-div" >
          <p className="Third-text-title">
            {language.main_page.app_title}
          </p>

          <div className="Third-app-explanation-div">
            <p  className="Third-app-explanation-p">
              {language.main_page.app_explanation}
            </p>
          </div>

          <div className="Link-button-div-white">
            <Link onClick={() => {
              this.props.ChangeHeaderColorAction(2);
            }} 
            className="Link-button" to={"/app"}> {language.main_page.know_more} {'>'} </Link>
          </div>

        </div>

        <div style={{ display: "flex", flexDirection: "column", justifyContent: 'space-between', display: "flex", alignItems: 'center', backgroundColor: "white", marginTop: -1 }}>
          {/* <Carousel 
            infiniteLoop
            autoPlay={true}
            speed={5000}
            showArrows={false}
            stopOnHover={false}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            >
            <div style={{ backgroundColor: "white", paddingLeft: 120 }}>
              <img src={Web_image_url} alt={"App_left"} style={{aspectRatio: 1, width: "60%" }} /> 
            </div>
            <div  style={{ backgroundColor: "white", paddingLeft: 120 }}>
              <img src={Web_image_url_2} alt={"App_left"} style={{aspectRatio: 1, width: "60%" }} /> 
            </div>
            <div  style={{ backgroundColor: "white", paddingLeft: 120 }}>
              <img src={Web_image_url_3} alt={"App_left"} style={{aspectRatio: 1, width: "60%" }} /> 
            </div>
          </Carousel> */}

          <img src={Web_image_url} alt={"App_left"} style={{aspectRatio: 1, width: "100%" }} /> 
          <div className="Four-main-div-Web" >
            <p className="Four-text-title" >{language.main_page.web_title}</p>
            <p className="Four-text-explanation" >
            {language.main_page.web_explanation}
            </p>
            <div className="Link-button-div">
              <Link className="Link-button" to={"/app"}> {language.main_page.know_more} {'>'} </Link>
            </div>
          </div>
        </div>


        <div className="Aurora-main-div" >
          <Link 
            className="Aurora-Image-div" 
            to={'/aurora'}
            onClick={() => { 
              this.props.ChangeHeaderColorAction(5); 
            }} >
            <img className="Aurora-Image-oficial" src={Aurora_image_url} alt={"Aurora"} />
          </Link>
        </div>

        <div className="Epistemic-Pro-Father-div">
          <div className="Epistemic-Pro-Main-div" >

            <div className="Epistemic-Pro-black-card-news" >

              <div className="Epistemic-Awards-Title-Card-div-1" >
                <h1 className="Epistemic-Awards-Title-Card-1" >{language.main_page.last_news_title}</h1>
                <p className="Epistemic-Awards-Explenation-Card-1">{language.main_page.last_news_explanation}</p>
              </div>

              <div className="Epistemic-Award-Title-1-Card-div-1" >
                <h1 className="Epistemic-Award-Title-1-Card-1" >Brasileira ganha prêmio de empreendedorismo feminino da Cartier</h1>
                <Link onClick={() => {
                  this.props.ChangeHeaderColorAction(5);
                }} className="Epistemic-Awards-See" to={"/blog"}> Ver</Link>
              </div>

              <div className="Epistemic-Awards-divider"> 
                <div style={{ width:"60%", height: 3, backgroundColor: "#5f2193", borderRadius: 20 }}  />
              </div>

              <div className="Epistemic-Award-Title-2-div-Card-1" >
                <h1 className="Epistemic-Award-Title-2-Card-1" >Epistemic's Marketing Director's Interview at the Futura Channel</h1>
                <Link onClick={() => {
                  this.props.ChangeHeaderColorAction(5);
                }} className="Epistemic-Awards-See" to={"/blog"}> Ver</Link>
              </div>

            </div>

            <div className="Epistemic-Pro-black-card">
              <h1 className="Epistemic-Pro-Title-Card-2">{language.main_page.epistemic_pro_title}</h1>
              <p className="Epistemic-Pro-Explanation-Card-2" >{language.main_page.epistemic_pro_explanation}</p>
              

              <div className="know-more-pro-Card-2">
                <Link onClick={() =>{
                  this.props.ChangeHeaderColorAction(2);
                }} className="Link-button" to={"/app"}> {language.main_page.know_more} {'>'} </Link>
              </div>
              
              <div className="Epistemic-Pro-div-Card-2">
                <img src={App_premium_url} alt="Epistemic-Pro-Image" className="Epistemo-Pro-Image-Card-2" />
              </div>
            </div>
          </div>
        </div>
        <div className="Footer-div-main" >
          <Footer black={false} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    ChangeLanguageAction, 
    FooterColorAction, 
    ChangeHeaderColorAction, 
    ChangeLanguageFeedbackAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Main);