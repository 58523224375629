import styled, { keyframes } from 'styled-components';

const makeImageCircle = keyframes`
  0% { border-radius: 10px; width: 530px; }
  100% { border-radius: 190px; width: 300px; }
`
const BackImageToNormal = keyframes`
  0% { border-radius: 190px; width: 300px; }
  100% { border-radius: 10px; width: 530px; }
`
const MakeImageMove = keyframes`
  0% {  width: 530px; }
  100% { width: 530px; }
`
const MakeImageMoveToNormal = keyframes`
  0% { width: 300px; }
  100% {  width: 530px; }
`



export const ImageBlog = styled.img`
  height: 300px;
  border-radius: 10px;
  animation-name: ${props => props.expand ? makeImageCircle : BackImageToNormal};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  object-fit: cover;
  display: flex;
  align-self: center;

  @media (max-width: 1280px) {
    flex-direction: column;
    animation-name: ${props => props.expand ? MakeImageMove : MakeImageMoveToNormal};
  }
`;

export const BlogVideo = styled.iframe`
  width: 530px;
  height: 300px;
  border-radius: 10px;
  border: none;
  display: flex;

  @media (max-width: 1500px) {
    width: 730px;
    display: flex;
  }

  @media (max-width: 1280px) {
    width: 530px;
    display: flex;
    align-self: center;
  }
`;
