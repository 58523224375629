import React, { Component } from 'react';
import api from '../../services/api';
import moment from 'moment';
import { Helmet } from 'react-helmet'
import { 
  FacebookShareButton, 
  FacebookIcon, 
  WhatsappIcon,
  WhatsappShareButton,
  TelegramIcon,
  TelegramShareButton,
  ViberIcon,
  ViberShareButton,
  TwitterShareButton,
  TwitterIcon
} from 'react-share';
import ReactGA from 'react-ga';

import "./style.css";
import { payload } from '../../services/payload';

class blog_text extends Component {

  state = {
    blog_info: {},
  };

  async componentDidMount() {
    const { name_id, creating } = this.props.match.params;
    ReactGA.pageview(`/blog/${name_id}`);

    if (name_id !== "null" ) {
      const response = await api.get(`${payload.endpoints.user}?name_id=${name_id}&action=get_one_new`);
      if (response.status === 200 ){
        this.setState({ blog_info: response.data.data });
        console.log(response);
      }
    } else {
      console.log("Deu erro");
    }
  }
  render() {
    const { blog_info } = this.state;
    return (
      <div className="Main_div_blog_text">
        
        <Helmet>
          <title>{`Epistemic - ${blog_info.title}`}</title>
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Epistemic" />
          <meta property="og:description" content={blog_info.title} />
          <meta property="og:image" content={blog_info.main_image}/>
        </Helmet>

        <div className="First_infos_div">
          <h1>{blog_info.title}</h1>
        </div>

        <div className="Main_image_div" >
          <img src={blog_info.main_image} width={500} style={{ aspectRatio: 1, borderRadius: 15 }} />
        </div>

        <div className="Share_buttons_div" >

          <div className="Share_button_space" > 

            <WhatsappShareButton
              title="Epistemic"
              url={"https://www.epistemic.com.br/?#/blog_text/110"}
              separator=" "
            >
              <WhatsappIcon size={20} />
            </WhatsappShareButton>

            <TelegramShareButton
              url={"https://www.epistemic.com.br/"}
              title={blog_info.title}
            >
              <TelegramIcon size={20} />
            </TelegramShareButton>
            
            <FacebookShareButton
              url={"https://www.epistemic.com.br/?#/blog_text/8"}
              quote={""}
              hashtag="#Epistemic"  
            >
              <FacebookIcon size={20} />
            </FacebookShareButton>

            <TwitterShareButton
              url={"https://www.epistemic.com.br/"}
              title={blog_info.title}
            >
              <TwitterIcon size={20} />
            </TwitterShareButton>
          </div>

        </div>

        <div dangerouslySetInnerHTML={{ __html: blog_info.news_html}} className="Blog_text_div"/>

        <div className="Author_info_and_post_date">
          <p> {this.showDate(blog_info.posted_on)}</p>
          <p className="author_p"> escrito por: {blog_info.author}</p>
        </div>
        <div>
        </div>
      </div>
    )
  }

  showDate = (posted) => {

    if (posted !== undefined) {
      const date = moment(posted).format("DD/MM/YYYY");
      const hour = moment(posted).format("HH:mm");

      return `publicado em ${date} às ${hour.replace(":", "h")}`
    }

  }
}

export default blog_text;