import { CHANGE_LANGUAGE_FEED_BACK } from '../actions/actionsTypes';

const initialState = {
  show: false
};

export const Language_Feed_Back_Reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE_FEED_BACK:
      return {
        ...state,
        show: action.show
      }

    default:
      return state;
  }
}