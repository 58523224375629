import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  ChangeLanguageAction, 
  FooterColorAction, 
  ChangeHeaderColorAction,
  ChangeLanguageFeedbackAction
} from '../../actions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { payload } from '../../services/payload';
import ReactGA from 'react-ga';

import api from '../../services/api';

import "./styles.css";

class New_Blog extends Component {
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.props.FooterColorAction(false);
    this.props.ChangeHeaderColorAction(5);

    ReactGA.pageview("/blog");


    const response = await api.get(payload.endpoints.user + "?action=get_all_news");
    this.setState({ 
      news: response.data.data.posts,
    });

  }
  constructor(props) {
    super(props);
    this.state = { 
      news: null
     }
  }

  render () { 
    const { language } = this.props.reducer.Language;
    const { show } = this.props.reducer.show_language_feedback;
    const { main_image, author, html } = this.state;
    return(
      <div className="Main_div_blog_view">
        <div className="Blog_div_row">
          {this.ShowNews()}
        </div>
      </div>
    )
  }
  ShowNews(){
    const { news } = this.state;
    const currentDate = moment().format("YYYY-MM-DD");
    if (news !== null) {
      return news.map((item, index) => {
        if(currentDate >= item.post_on && item.active === 1) { 
            return (
              <div
                key={index} 
                className="Blog_main_div">
                <Link 
                  to={`/blog_text/${item.name_id}/${false}`}
                  className={"News_button"}
                >
                  <img 
                    src={item.main_image} 
                    width={500} 
                    style={{ aspectRatio: 1, borderRadius: 15 }} 
                    alt={"Imagem aqui"} />
                  <h4>{this.showTopicName(item.topic)}</h4>
                  <h2>{item.title}</h2>
                </Link>
              </div>)
        }
      })
    }
  }
  showTopicName(_topic) {
    switch (_topic) {
      case 1: return "Sáude";
      case 2: return "Epistemic";
      case 3: return "Aurora";
      case 4: return "Novidades";
      default: return "Topico nao implementado";
    }
  }
}

const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ 
    ChangeLanguageAction, 
    FooterColorAction, 
    ChangeHeaderColorAction, 
    ChangeLanguageFeedbackAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(New_Blog);