import React, { Component, useEffect, useRef, useState, useCallback } from 'react';
import { Container, Content, Background_Image, ShowErrorMessage, ErrorMessage, FotterDiv } from './styles';
import Section from '../../components/Section';
import ShareIcons from '../../components/ShareIcons';
import SearchBlogPost from '../../components/SearchBlogPost';
import BlogHelloImage from '../../components/BlogHelloImage';
import Blog_background from '../../img/Blog-background.png';
import { FaExclamationTriangle } from 'react-icons/fa';
import Footer from '../../components/Footer';
import BottomScrollListener from 'react-bottom-scroll-listener';
import api from '../../services/api';
import { payload } from '../../services/payload';
import data from '../../BlogPostDataEx';
import ReactLoading from 'react-loading';
import webconsole from '../../config/degub';
import { ChangeHeaderColorAction } from '../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

let postLimit = 1;

function Blog(props) {

  const [BlogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [result, setNoResult] = useState(false);
  const [error, setError] = useState(false);
  const [filter, SetFilter] = useState("");
  const [maxpost, SetMaxPost] = useState(0);
  const [search, SetSearch] = useState(false);
  const [redirectFromURL, SetRedirectFromURL] = useState(false);
  const [ChangeHeaderColor, SetChangeHeaderColor] = useState(true);


  const scrollToRefObject = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let scroll = false;

    const executeScroll = () => scrollToRefObject(myRef);

    const URL = window.location.hash;
    let search_ID = "";

    if(ChangeHeaderColor){
      props.ChangeHeaderColorAction(6);
      SetChangeHeaderColor(false);
    }
    

    if(URL !== "") {
      const slicedURL = URL.split("/");
      webconsole.log(slicedURL);
      if(slicedURL.length === 3 && !scroll) {
        search_ID = slicedURL[2];
        SetRedirectFromURL(true);
        GetPostFromID(search_ID);
        scroll = true;
        executeScroll();
      } else {
        SetRedirectFromURL(false);
        getAllPosts();
        scroll = false;
      }
    }

  }, [ChangeHeaderColor, props, redirectFromURL]);

  async function getAllPosts () {
    try {
      const response = await api.get(payload.endpoints.user, {
        params: {
          action: "get_posts",
          post_limit: postLimit
        }
      });

      if (response.status === 200) {
        webconsole.log(response.data.data);
        setBlogPosts(response.data.data.posts);
        SetMaxPost(response.data.data.activedPosts);
      } else {
        setError(true);
      } 

      setLoading(false);
    } catch(e) {
      webconsole.log(e);
      setError(true);
    }
  }


  async function GetPostFromID (_searchID) {
    try {
      // webconsole.log(`Vou fazer uma busca pelo id: ${_searchID}`);
      const response = await api.get(payload.endpoints.user, {
        params: {
          action: "get_one_new",
          name_id: _searchID
        }
      });

      setBlogPosts(response.data.data);

      if(response.status === 200 ) {
        setBlogPosts(response.data.data);
        SetMaxPost(1);
      } else {
        setError(true);
      }

      setLoading(false);
      webconsole.log(response);

    } catch(e) {
      webconsole.log(e);
    }
  }

  async function SearchPost () {
    if (filter !== "") {

      setLoading(true);
      setError(false);
      setNoResult(false);
      try {
        const response = await api.get(payload.endpoints.user, {
          params: {
            action: "get_posts_by_filter",
            filter: filter
          }
        });

        if (response.status === 200) {
          if(response.data.data.posts.length === 0) {
            setNoResult(true);
          } 

          setBlogPosts(response.data.data.posts);
        } else {
          setError(true);
        } 
        setLoading(false);
      } catch(e) {
        webconsole.log(e);
        setError(true);
      }
    } else {
      alert(`Para realizar uma busca é preciso de um nome :(`);
    }

  }

  function Loading() {

    //Aqui criamos o loading enquanto a requisição para a API não termina
    if(loading) {
      return (
        <ReactLoading type={"spinningBubbles"} color={"#5F2F85"} height={'10%'} width={'10%'} />
      )
    }

    // Caso aconteça algum erro rendereizamos uma mensagem para o usuádio
    if(error){
      return(
        <ShowErrorMessage>
          <ErrorMessage>
            Ocorreu um erro durante o carregamento. <br/> tente novamente mais tarde.
          </ErrorMessage>
          <FaExclamationTriangle color="orange" size={100} />
        </ShowErrorMessage>
      )
    }

    if(result) {
      return(
        <ShowErrorMessage>
          <ErrorMessage>
            Lamento não encontramos nenhum post com essas palavras.
          </ErrorMessage>
          <FaExclamationTriangle color="orange" size={100} />
        </ShowErrorMessage>
      )
    }

    /* Parte responsavel por desenhar todos os cards dos posts */
    if(redirectFromURL){
      return(
        <Content>
          <ShareIcons title={BlogPosts.title} color={BlogPosts.color} />
          <Section
            title={BlogPosts.title}
            color={BlogPosts.color}
            date={BlogPosts.posted_on}
            tags={BlogPosts.tags}
            video={BlogPosts.video}
            introduction={BlogPosts.introduction}
            image={BlogPosts.image}
            text={BlogPosts.text}
            fromLink={true}
            autofocus={true}
            onClickTag={(value) => {webconsole.log(`Cliquei na tag`); webconsole.log(value); }}
          />
        </Content>
      )
    }

    return (
      BlogPosts.map((item, index) => (
        <Content >
          <ShareIcons title={item.title} color={item.color} />
          <Section
            key={index}
            title={item.title}
            color={item.color}
            date={item.posted_on}
            tags={item.tags}
            video={item.video}
            introduction={item.introduction}
            image={item.image}
            text={item.text}
            fromLink={false}
            onClickTag={(value) => {webconsole.log(`Cliquei na tag`); webconsole.log(value); }}
          />
        </Content>
      ))
    )
  }
  
  async function GetMorePosts() {
    if(!loading){
      if(maxpost !== BlogPosts.length && !search && !redirectFromURL) {
        setError(false);
        postLimit ++;

        try {
          const response = await api.get(payload.endpoints.user, {
            params: {
              action: "get_posts",
              post_limit: postLimit
            }
          });

          if (response.status === 200) {
            setBlogPosts(response.data.data.posts);
          } else {
            setError(true);
          } 

          webconsole.log(response.data.data.posts);
          setLoading(false);
        } catch(e) {
          webconsole.log(e);
          setError(true);
        }
      }
    }
  }

  return (
    <Container >
      {/* <button onClick={() => executeScroll()}>
        Teste
      </button> */}
      <Background_Image 
        src={Blog_background}
      />

      <BottomScrollListener onBottom={GetMorePosts} />

      {/* Imagem com o titulo da página de blog */}
      <BlogHelloImage />
      
      {/* Barra de busca de blogPost */}
      <SearchBlogPost 
        onClick={() => SearchPost()}
        onChange={(value) =>  {
          SetFilter(value.target.value)
          SetSearch(true);
        }}
        value={filter}
      />

      {/* Essa div serve de referencia para poder focar no post quando o usuário chegar via um Link direto */}
      <div ref={myRef} />
      
      {/* Aqui carregamos o Loading ou o Post após a requisicao da api ter termiando */}
      {Loading()}
      
      <FotterDiv >
        <Footer black={false} />
      </FotterDiv>
    </Container>
  )
}


const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    ChangeHeaderColorAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Blog);