import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 50px;
  margin-top: 0px;
  width: 50%;
  padding: 0px 20px 0px 20px;
  @media (max-width: 1280px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 20px;
  }
`;

export const Title = styled.h1` 
  color: ${props => props.color ? props.color : "black"};
  text-decoration: underline;
  text-align: left;
  font-family: "axis";
  padding-left: 0px;
  padding-right: 0px;
  
  @media (max-width: 1280px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

export const SecondInformation = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  padding-right: 0px;
  @media (max-width: 1280px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`

export const Introduction = styled.p`
  color: black;
  font-size: 15px;
  margin-top: 10px;
  text-align: justify;
  padding-left: 0px;
  padding-right: 0px;

  @media (max-width: 1280px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`

export const BlogPostDate = styled.h1`
  font-size: 18px;
  color: black;
`

export const Tags = styled.pre `
  background-color: ${props => props.color ? props.color : "black"};
  border-radius: 10px;
  font-size: 12px;
  color: white;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  font-family: "axis";
  padding-top: 5px;
  padding-bottom: 5px;
`

export const TagsGroup = styled.div `
  display: flex;
  flex-direction: row;
`