import React from 'react';
import Routes from './routes';
import "./styles.css";
import ReactGA from 'react-ga';
import GA_TRACKING_ID from './config/ga_tracking_id';

import GlobalStyle from './styles';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

ReactGA.initialize(GA_TRACKING_ID);

const App = () => (
  <div className="App" >
    <GlobalStyle />
    <Routes />
  </div>
);

export default App;
