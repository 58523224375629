import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BackgroundImage = styled.div`
  background-image: url(${props => props.background_image}); 
  width: 100%;
  height: 1500px;
  background-size: auto auto;
  background-repeat: no-repeat;
  position: absolute;
  margin-top: -150px;
  z-index: -10;
`
export const Logo = styled.img`
  width: 100px;
  height: 100px;
  margin-top: 20px;
  align-self: center;
`

export const LoginCard = styled.form`
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 500px;
  border-radius: 10px;
  box-shadow: 0 0 5em rgba(0, 0, 0, 0.5);
  margin-top: 20px;
`

export const EmailInput = styled.input`
  margin-top: 20px;
  height: 30px;
  align-self: center;
  width: 80%;
  background-color: white;
  border-radius: 20px;
  padding: 10px;
  border-style: none;
`

export const PassworInput = styled.input`
  background-color: white;
  margin-top: 20px;
  height: 30px;
  align-self: center;
  width: 80%;
  border-radius: 20px;
  padding: 10px;
  border-style: none;
`

export const ButtonLogin = styled.button`
  margin-top: 20px;
  height: 30px;
  align-self: center;
  width: 50%;
  border-radius: 20px;
  font-size: 18px;
  border-style: none;
  font-family: 'axis';

  &:hover {
    cursor: pointer;
    background-color: #DF7400;
    color: white;
  }
  
`
export const Title = styled.h1`
  text-align: center;
  margin-top: 20px;
  color: white;
  font-family: 'axis';
`

export const ForgotPassword = styled.a`
  text-align: center;
  margin-top: 20px;
  color: white;
  font-family: 'axis';

  &:hover {
    cursor: pointer;
  }
`
