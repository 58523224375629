import { SAVE_AUTHOR_INFOS } from '../actions/actionsTypes';

const initialState = { infos: {}, logged: false };

export const AuthorInfosReducer = (state = initialState, action) => {
  switch(action.type) {
    case SAVE_AUTHOR_INFOS: 
      return {
        ...state,
        infos: action.infos,
        logged: action.logged
      }
    default: 
      return state;
  }
}