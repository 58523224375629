import React, { useState } from 'react';
import { Container, Content, InputText, SearchButton, SearchTitle } from './styles';
import { FaSearch } from 'react-icons/fa';

export default function SearchBlogPost ({ onClick, onChange, value }) {

  return (
    <Container>
      
      <SearchTitle> 
        Buscar
      </SearchTitle>

      <Content>

        <InputText
          onChange={onChange}
          value={value}
        />

        <SearchButton 
          type="submit" 
          onClick={onClick}
        > 
          <FaSearch size={20} color={"white"} />
        </SearchButton>
    
      </Content>
    </Container>
  )
}