import React, { useEffect, useState } from 'react';
import { ImageBlog, BlogVideo } from './styles';

export default function BlogImage ({ image, video, expand }) {

  const [showVideo, setVideo] = useState(false);

  useEffect(() => {
    if(video !== null && video !== "") {
      setVideo(true);
    } else {
      setVideo(false);
    }
  }, [image, video]);
  

  function renderVideoOrImage() {
    if(showVideo) {
      return (
        <BlogVideo 
          src={video}
        />
      )
    }
    return (
      <ImageBlog 
        expand={expand}
        src={image} 
      />
    )
  }

  return renderVideoOrImage();
}