const languages = {
  pt_BR: {
    footer: {
      sing: "Assine",
      service: "Serviço",
      contact: "Contato",
      developed_by: "Desenvolvido por",
      financed_by: "Financiado por",
      incubated_in: "Incubado em",
      email: "contato@epistemic.com.br"
    },
    language: "pt_BR",
    google_badges: "../img/pt_BR/google-play-badge.png",
    header: {
      home: "INICIO",
      app: "APP",
      aurora: "AURORA",
      doctors: "MÉDICOS",
      about: "SOBRE",
      blog: "BLOG",
      news: "NOTÍCIAS",
      contact: "CONTATO"
    },
    main_page: {
      your: "Sua",
      health: "Saúde",
      our: "Nossa",
      mission: "Missão.",
      know_more: "Saiba mais",
      our_solutions: "Conheça nossas soluções",
      welcome: "Seja bem-vindo(a). Somos a Epistemic, uma startup que dia após dia desenvolve ferramentas com o uso de tecnologia para atingir nosso principal objetivo:",
      welcome_bold: " melhorar a vida de pacientes com epilepsia e de seus familiares.",
      app_title: "EpistemicApp",
      app_explanation: "Tenha seu diário de epilepsia na palma de sua mão. Conte como foi seu dia, suas atividades, sua dieta e adicione eventos de crise quando tiver. Compartilhe tudo com seu médico para ter um tratamento mais eficaz.",
      web_title: "EpistemicWeb",
      web_explanation: "Desenvolvemos uma plataforma web dedicada ao uso médico. Nela o médico pode visualizar o histórico do paciente, registrado em seu diário, em forma de relatórios e gráficos. O EpistemicWeb ajuda o médico a ter mais informação sobre seu paciente.",
      aurora_title: "AURORA",
      aurora_development: "Em desenvolvimento",
      aurora_phrase: "“Saiba de suas crises antes mesmo que elas aconteçam”",
      aurora_explanation: "Aurora é um dispositivo vestível de previsão de crises.",
      epistemic_pro_title: "Epistemic Pro",
      epistemic_pro_explanation: "Combine a tecnologia ao controle de sua saúde com recursos exclusivos do plano Pro do nosso app.",
      last_news_title: "Últimas notícias",
      last_news_explanation: "Acompanhe as atualizações do nosso blog sobre as novidades da Epistemic.",
    },
    research: {
      header: 'Pesquisa Epistemic',
      explainer: 'Ajude a Epistemic a desenvolver melhores soluções para',
      bold: 'pessoas com epilepsia, cuidadores e profissionais da saúde.'
    },
    app_page: {
      epistemic_app_title: "Epistemic App",
      epistemic_app_phrase: "Sua saúde na palma da sua mão",
      epistemic_app_explanation: "Um diário de epilepsia com perguntas do seu dia-a-dia e suas crises. Indicadores, medicamento e compartilhamento com seu médico. Todas as funcionalidades possíveis para auxiliar o controle de sua epilepsia junto ao seu médico.",
      create_share_title: "Crie e Compartilhe",
      create_share_explanation_1:"Nosso aplicativo foi criado no formato de um diário. É recomendável inserir dados no app diariamente, mesmo que o paciente não tenha tido uma crise. As informações dos dias sem crise também são muito importantes para seu médico no momento de determinar o melhor tratamento.",
      create_share_explanation_2:"Temos sempre a preocupação com a comodidade do paciente na hora de administrar suas crises. Nem sempre lembramos tudo aquilo que vivemos. Por isso o Diário Epistemic oferece um questionário com uma sequência de perguntas sobre seus hábitos e suas crises.",
      create_share_explanation_3: "As perguntas do diário são ajustáveis por paciente e ajudam o médico a entender seu quadro atual para que o tratamento seja feito da melhor forma possível. ",
      create_share_explanation_4: "Nosso aplicativo é integrado a uma plataforma digital para médicos. Se o paciente autorizar, o médico recebe seus dados nessa plataforma no formato de gráficos. Estes incluem dados de crises, humor, atividade física, sono, alimentação, funcionamento do intestino, ingestão de medicamentos, entre outros.",
      indicators_title: "Indicadores",
      indicators_explanation: "Experimente uma nova forma de visualizar dados sobre sua saúde. Acompanhe seus registros de humor, crises, atividade física, sono e medicamentos através de gráficos. Seus registros podem ser editados clicando nos dados do gráfico Diário. E, clicando num medicamento no gráfico, este também pode ser editado. Configure seus medicamentos para receber notificações no horário de tomá-los.",
      help_center_title: "Central de Ajuda",
      help_center_explanation: "Esteja sempre atualizado sobre as melhores práticas da administração da epilepsia no dia-a-dia. Acompanhe dicas nutricionais de profissionais especializados, e visualize as melhores opções no momento de agir diante de uma crise.",
      seizures_title: "Crises",
      seizures_explanation_1: "Criamos um questionário fluído em nosso aplicativo para que você possa registrar suas crises de forma fácil. Há também a opção de inserir texto livre.",
      seizures_explanation_2: "Caso o você não deseje responder ao questionário no momento, mas quiser deixar registrada uma crise, basta clicar no raio no canto superior direito da tela inicial. Caso queira inserir mais informação dessa crise, basta editar o registro.",
      seizures_explanation_3: "Todos os registros podem ser editados indo em Indicadores e clicando nos dados do gráfico Diário.",
      epistemic_pro_title: "Epistemic Pro",
      epistemic_pro_explanation_1: "Criamos o EpistemicPro para ser um plus nas funcionalidades disponíveis em nosso app. Assinando o plano Pro, o paciente poderá sincronizar seu SmartWatch Samsung com nosso aplicativo, que passará a registrar automaticamente dados sobre atividade física e sono em seu diário.",
      epistemic_pro_explanation_2: "Além disso, o usuário Pro pode enviar suas informações para seu médico sem nenhum custo adicional.",
      download_title:  "Faça Download",
      download_explanation:  "Acesse sua loja de aplicativos e faça o download. Aproveite o máximo em funcionalidades num diário de epilepsia.",
    },
    aurora_page: {
      page_title: "AURORA",
      page_subtitle: "Vista-se para o futuro",
      page_phrase: "Saiba de suas crises antes mesmo que elas aconteçam",
      development: "Em desenvolvimento",
      epistemic: "Epistemic",
      epistemic_explanation: "Estamos trabalhando duro no desenvolvimento do dispositivo vestível AURORA. Desenhado para prever crises com uma média de meia hora de antecedência nosso dispositivo será o que há de mais inovador no tratamento diário da epilepsia.",
      practicality_title: "Praticidade",
      practicality_explanation_1: "Pensando na necessidade de ergonomia do usuário, o AURORA está sendo desenvolvido para parecer-se com um fone de ouvido esportivo. Ele inclusive terá a função de fone!",
      practicality_explanation_2: "Quando em contato com a cabeça do paciente, o dispositivo será capaz de medir o seu eletroencefalograma. É através dessa informação que conseguiremos prever as crises epilépticas.",
      daily_use: "Uso diário",
      night_use: "Uso noturno",
      innovative_project_title: "Projeto Inovador",
      innovative_project_explanation_1: "De acordo com a Organização Mundial da Saúde (OMS) a epilepsia é a mais comum e a mais grave dentre as condições neurológicas crônicas. São mais de 65 milhões de pessoas com epilepsia no mundo, das quais 80% reside em países em desenvolvimento. Apesar de existirem medicamentos e cirurgias, estes não têm efeito para um terço dos pacientes. Estes pacientes se beneficiariam muito com um aviso de uma crise ainda por vir.",
      innovative_project_explanation_2: "Até hoje não existe nenhum dispositivo que consiga prever crises epilépticas.",
      innovative_project_explanation_3: "A Epistemic tem um software de previsão testado e está finalizando sua implementação num dispositivo portátil que chamamos de AURORA. Um dos significados de AURORA é “as primeiras manifestações de algo; o princípio”.",
      innovative_project_explanation_4: "O AURORA tem a finalidade de melhorar a qualidade de vida de pessoas com epilepsia e seus familiares. Além de alertar o paciente, o dispositivo alertará cuidadores e familiares autorizados através do nosso app.",
      innovative_project_explanation_5: "Sabendo quando uma crise está por vir, pacientes podem realizar atividade que hoje podem ser consideradas perigosas, e parar somente quando avisados. Alguns neurologistas dizem que crises poderão até ser evitadas tomando a medicação certa na hora do alerta.",
      innovative_project_explanation_6: "Este projeto é o resultado de 10 anos de pesquisa dos cientistas da Epistemic.",
    },
    blog_page: {
      last: "Últimas",
      updateds: "atualizações",
      news_1_title: "Brasileira ganha prêmio de empreendedorismo feminino da Cartier >",
      news_1_explanation: "A revista pequenas empresas grandes negócios noticiou o prêmio Cartier Women's Initiative awards 2018 recebido por Paula Gomez, CEO da Epistemic.",
      news_2_title: "Cartier vai premiar mulheres empreendedoras >",
      news_2_explanation: "Ganhadoras do prêmio em 2018 foram mencionadas no artigo.",
      news_3_title: "Epistemic no Canal Futura >",
      news_3_explanation: "Veja a intrevista do Diretor de Marketing da Epistemic no programa Conexão.",
      news_4_title: "A revista do CIETEC também publicou o prêmio da Cartier >",
      news_4_explanation: "O CIETEC, incubadora onde a Epistemic está sediada, também fez seu reconhecimento ao prêmio recebido pela empresa.",
      news_5_title: "A Epistemic foi uma das ganhadoras do prêmio Shark Tank da Epilepsy Foundation fo America >",
      news_5_explanation: "A Epistemic foi uma das ganhadoras do prêmio Shark Tank da Epilepsy Foundation fo America",
      news_6_title: "Ganhamos!  Braisileira Paula Gomez ganhou o prêmio Cartier Women's Initiative Awards 2018 >",
      news_6_explanation: "A revista 'Marie Claire' cobriu o evento evento da Cartie, no qual Paula Gomez, CEO da Epistemic, ganhou o prêmio Cartier Women's Initiative Awards 2018.",
      news_7_title: "Epistemic selecionada para programa de inovação do SEBRAE",
      news_7_explanation: "A Epistemic foi um dos projetos selecionados por meio do Catalisa ICT, iniciativa de inovação do Sebrae. Com o Sistema de Gestão no Tratamento de Epilepsia, a Epistemic busca trazer condições para que pessoas com epilepsia tenham mais autonomia.",
      news_8_title: 'Aurora, a "tiara" que prevê crises epilépticas, finalista dos Premios Fundación MAPFRE à Inovação Social',
      news_8_explanation: "O dispositivo vestível da Epistemic, junto do sistema composto por aplicativo e plataforma para médicos, foi um dos finalistas na categoria Melhora da saúde e tecnologia digital (e-health).",
      news_9_title: 'E se fosse possível prever uma crise de epilepsia com meia hora de antecedência?',
      news_9_explanation: "Esse é um dos objetivos da Epistemic! Nessa entrevista, Paula Gomez - fundadora da Epistemic, fala sobre o Epistemic App e o Aurora, inovações tecnológicas para melhorar a qualidade de vida de pessoas com epilepsia.",
    },
    about_page: {
      main_page_title_1: "Quem",
      main_page_title_2: "Somos,",
      main_page_title_3: "e Por que",
      main_page_title_4: "Somos?",
      contact: "Entre em contato >",
      message: "Insira sua mensagem ...",
      placeholder_name: "Ex.: John Snow",
      label_name: "Nome completo",
      placeholder_email: "exemplo@email.com",
      label_email: "Email",
      placeholder_phone: "99 99999-9999",
      label_phone: "Telefone",
      label_country: "País",
      send: "Enviar",
      epistemic: "A Epistemic",
      our_awards: "Nossas conquistas",
      finep_women: "Prêmio Mulheres Inovadoras - Finep",
      options: {
        info: "Pedido de informação",
        clinical: "Testes clínicos",
        app: "App",
        web_platform: "Plataforma Web",
        aurora: "Aurora",
        suggestion: "Sugestão",
        complaint: "Reclamação",
        selective: "Processo seletivo",
        other: "Outros",
        delete_account: "Excluir conta do Epistemic App"
      },
      text: {
        line_1: "A Epistemic começou de uma pesquisa científica sobre a previsão de crises epilépticas utilizando sinais de eletroencefalograma. Cientistas do mundo todo estão pesquisando para encontrar uma solução para esse problema. A Epistemic tem resultados compatíveis com o estado da arte, compatíveis com as melhores pesquisas do mundo.",
        line_2: "Partimos então para o desenvolvimento de um dispositivo vestível para uso diário que alertasse sobre uma crise ainda por vir. Hoje chamamos esse projeto de Aurora. É um projeto audacioso que requer muito trabalho de pesquisa e desenvolvimento de ponta: ",
        line_3: "Estamos hoje no final dessa jornada. Entraremos logo na fase de testes clínico, para, depois disso, poderemos disponibilizar o produto no mercado.",
        line_4: "Durante essa jornada tivemos a oportunidade de conviver com muitos médicos e pacientes, e pudemos entender melhor suas necessidades. Assim começamos a desenvolver mais produtos, com a ajuda da tecnologia, para ajudar no gerenciamento da epilepsia.",
      },
      about_alert: {
        sucess: "Sucesso ao enviar email!",
        fail: "Ocorreu um erro",
        empty: "Preencha todos os campos",
      }
    },
    contact_page: {
      talk: "Fale",
      us: "Conosco",
      when: "Quando",
      wish: "Quiser",
      test: "Fale </br> Conosco",
      delete: {
        our_policy: "Nossa política de exclusão de dados:",
        item_a: "a) Serão excluídos em um prazo de até sete dias úteis os seus dados pessoais que possam de alguma forma te identificar como indivíduo: Nome, email, número de telefone, idade exata e anotações textuais realizadas no seu diário.",
        item_b: "b) Serão mantidos os outros dados de forma anonimizada.",
        process: "Sobre o processo de exclusão da conta:",
        step_1: "Passo 1: Envie um e-mail para contato@epistemic.com.br contendo o seu nome de usuário do Epistemic App. Ele é um número de telefone que você usa para fazer login.",
        step_2: "Passo 2: Aguarde a confirmação por e-mail de que seus dados foram excluídos.",
      }
    }
  },
  en_US: {
    footer: {
      sing: "Subscribe",
      service: "Service",
      contact: "Contact",
      developed_by: "Developed by",
      financed_by: "Financed by",
      incubated_in: "Incubated in",
      email: "contact@epistemic.com.br"
    },
    language: "en_US",
    google_badges: "../img/en_US/google-play-badge.png",
    header: {
      home: "HOME",
      app: "APP",
      aurora: "AURORA",
      doctors: "PHYSICIANS",
      about: "ABOUT",
      blog: "BLOG",
      news: "NEWS",
      contact: "CONTACT"
    },
    main_page: {
      your: "Your",
      health: "Health",
      our: "Our",
      mission: "Mission.",
      know_more: "Know more",
      our_solutions: "Learn about our solutions",
      welcome: "Welcome! Epistemic is a startup that continuously develops technological tools to achieve our main goal:",
      welcome_bold: "  to improve the lives of patients with epilepsy and that of their loved ones.",
      app_title: "EpistemicApp",
      app_explanation: "Keep your epilepsy diary in the palm of your hand. Register everything about your day, your activities, your diet and add seizure events when you have them. Share everything with your doctor for a more effective treatment.",
      web_title: "EpistemicWeb",
      web_explanation: "We have developed a web platform dedicated to medical use. In it, the doctor can view the patient's history recorded in his/her diary, in the form of reports and graphs. EpistemicWeb helps the physician have more information about his/her patient.",
      aurora_title: "AURORA",
      aurora_development: "Under development",
      aurora_phrase: "“Learn about your seizures before they happen”",
      aurora_explanation: "Aurora is a wearable device that predicts epileptic seizures.",
      epistemic_pro_title: "Epistemic Pro",
      epistemic_pro_explanation: "Join technology to the control of your health using the exclusive features from our app’s Pro plan.",
      last_news_title: "Latest News",
      last_news_explanation: "Follow the updates about Epistemic in our blog.",
    },
    research: {
      header: 'Epistemic Research',
      explainer: 'Help Epistemic develop better solutions for',
      bold: 'people with epilepsy, caregivers and healthcare workers.'
    },
    app_page: {
      epistemic_app_title: "Epistemic App",
      epistemic_app_phrase: "Your health in the palm of your hand",
      epistemic_app_explanation: "EpistemicApp is an epilepsy diary with questions about your daily life and your seizures. Indicators, medication and sharing data with your doctor. Have all possible functionalities to aid your doctor in the control of your epilepsy.",
      create_share_title: "Create and Share",
      create_share_explanation_1:"Our app was created to be an epilepsy diary. We recommend that you insert data in your app daily, regardless of having had a seizure or not. Information about days without seizures are also important to your doctor when he/she is determining the best treatment.",
      create_share_explanation_2:"We are constantly concerned with the patient’s convenience. We often forget our experiences in life. EpistemicApp offers a questionnaire with a sequence of questions about your habits and your seizures.",
      create_share_explanation_3: "Questions in the diary are adjustable by patient and they help doctors understand the patient’s current situation to better guide the treatment given.",
      create_share_explanation_4: "Our app is integrated with a web platform for doctors. With the patient’s authorization, the doctor receives his/her data from the diary and can visualize it in graphical form. These include data on seizures, mood, physical activity, sleep, food, bowel function, medication intake, among others.",
      indicators_title: "Indicators",
      indicators_explanation: "Experience a new way to visualize your health data. Track your mood, seizures, physical activity, sleep and medication records using charts. Your records can be edited by clicking on the data in the Journal chart. And, by clicking on a medication in the graph, it can also be edited. Configure your medications to receive notifications at the right time to take them.",
      help_center_title: "Help Center",
      help_center_explanation: "Be up to date on the best practices of day-to-day epilepsy management. Follow nutritional tips from specialized professionals and visualize the best options when dealing with a seizure.",
      seizures_title: "Seizures",
      seizures_explanation_1: "We´ve created a fluid questionnaire in our app so that the patients can easily record their seizures. There is also the option to insert free text.",
      seizures_explanation_2: "If the patient wishes to record a seizure but does not wish to answer the questionnaire, he/she can click on the lightning icon on the upper right corner of the home screen. He/She can insert more information about this specific seizure later on by editing the record.",
      seizures_explanation_3: "All records can be edited by going to Indicators and clicking on the data in the Journal chart.",
      epistemic_pro_title: "Epistemic Pro",
      epistemic_pro_explanation_1: "EpistemicPro is a plus in features available in the app. By subscribing the Pro plan, the patient will be able to synchronize his/her Samsung SmartWatch with EpistemicApp, which will automatically record data on physical activity and sleep in his/her diary.",
      epistemic_pro_explanation_2: "",
      download_title:  "Download",
      download_explanation:  "You can download EpistemicApp from your app store, or just follow the link below. Make the most of features in an epilepsy diary.",
    },
    aurora_page: {
      page_title: "AURORA",
      page_subtitle: "Dress for the future",
      page_phrase: "Be aware of your seizures before they happen",
      development: "Under development",
      epistemic: "Epistemic",
      epistemic_explanation: "We are working hard on the development of the AURORA wearable device. Designed to predict seizures with an average of half an hour in advance, our device will be the most innovative in daily treatment of epilepsy.",
      practicality_title: "Practicality",
      practicality_explanation_1: "Focusing on the user's need for ergonomics, the AURORA is being developed to look like a sports headset. In fact, it will also have a phone’s functionality!",
      practicality_explanation_2: "When in contact with the patient's head, the device will be able to measure its electroencephalogram. Through this signal we’ll be able to predict epileptic seizures.",
      daily_use: "Daily use",
      night_use: "Night use",
      innovative_project_title: "Innovative Project",
      innovative_project_explanation_1: "According to the World Health Organization (WHO), epilepsy is the most common and the most serious among chronic neurological conditions. There are more than 65 million people with epilepsy in the world, 80% of whom live in developing countries. Although there are medications and surgeries available, they do not have any effect in one third of the patients. These patients would benefit from an early alarm of an incoming seizure.",
      innovative_project_explanation_2: "To date, there is no device that can predict an incoming epileptic seizure.",
      innovative_project_explanation_3: "Epistemic has a tested and proved prediction software and is finalizing its implementation in a portable device that we call AURORA. One of the meanings of AURORA is “the first manifestations of something; the beginning”.",
      innovative_project_explanation_4: "AURORA aims to improve the quality of life of people with epilepsy and their loved ones. In addition to alerting the patient, the device will alert authorized caregivers and family members through our app.",
      innovative_project_explanation_5: "Knowing when a seizure will come, patients can resume activities that today they may consider dangerous and stop only when warned. Some neurologists say that some seizures will be avoided taking the right medicine when the alert strikes.",
      innovative_project_explanation_6: "This project is the result of 10 years of research from Epistemic’s group of scientists.",
    },
    blog_page: {
      last: "Last",
      updateds: "Updates",
      news_1_title: "Paula Gomez, founder of Epistemic wins the Cartier Women's Initiative Awards 2018 >",
      news_1_explanation: "The magazine Pequenas Empresas Grandes Negócios reported Paula's reception of the Cartier Women's Initiative Awards 2018.",
      news_2_title: "Cartier Initiative Awards 2019 is open for enrollment >",
      news_2_explanation: "2018's winners of the award were mentioned in the article.",
      news_3_title: "Epistemic on the Futura Channel >",
      news_3_explanation: "Check out Epistemic's Marketing Director's Interview at the Futura Channel on the show Conexão (Connection).",
      news_4_title: "The CIETEC magazine also reported the Cartier Award >",
      news_4_explanation: "The CIETEC, where Epistemic is incubated, also featured Paula Gomez's, co-founder of Epistemic, reception of the Cartier Women's Initiative Awards 2018 on their magazine.",
      news_5_title: "Epistemic was one of the 2017 Epilepsy Foundation’s Shark Tank competition Winners >",
      news_5_explanation: "Epistemic was one of the 2017 Epilepsy Foundation’s Shark Tank competition Winners",
      news_6_title: "We won! Brazilian Paula Gomez won the Cartier Women's Initiative Awards 2018 >",
      news_6_explanation: "The magazine 'marie claire' also featured Paula Gomez's, co-founder of Epistemic, reception of the Cartier Women's Initiative Awards 2018.",
      news_7_title: "Epistemic selected for innovation program by SEBRAE",
      news_7_explanation: "Epistemic was one of the selected projects through the Catalisa ICT inniciative from SEBRAE. With its Epilepsy Treatment Management System, Epistemic seeks to enable people with epilepsy to have more autonomy.",
      news_8_title: 'Aurora, the "headband" that forecasts epileptic seizures, finalist at the Fundación MAPFRE Social Innovation Awards',
      news_8_explanation: "Epistemic's wearable device, along with its accompanying system of mobile app and web platform for doctors was one of the finalists at the Health improvement and digital technology (e-health) category.",
      news_9_title: 'What if it was possible to predict an epileptic seizure half an hour in advance?',
      news_9_explanation: "This is one of Epistemic's goals! In this interview, Paula Gomez - Epistemic's founder, talks about the Epistemic App and Aurora, innovations aimed at improving the quality of life for people with epilepsy.",
    },
    about_page: {
      main_page_title_1: "Who",
      main_page_title_2: "and Why",
      main_page_title_3: "are we?",
      main_page_title_4: "",
      contact: "Contact >",
      message: "Enter your message ...",
      placeholder_name: "Ex.: John Snow",
      label_name: "Full name",
      placeholder_email: "example@email.com",
      label_email: "Email",
      placeholder_phone: "999-999-9999",
      label_phone: "Cellphone",
      label_country: "Country",
      send: "Send",
      epistemic: "The Epistemic",
      our_awards: "Our Awards",
      finep_women: "Innovative Women Award - Finep",
      options: {
        info: "Request for Information",
        clinical: "Clinical tests",
        app: "App",
        web_platform: "Web plataform",
        aurora: "Aurora",
        suggestion: "Suggestion",
        complaint: "Complaint",
        selective: "Selective Process",
        other: "Others",
        delete_account: "Delete Epistemic App account"
      },
      text: {
        line_1: "Epistemic started from a research done in prediction of epileptic seizures using electroencephalogram signals. Scientists from all over the world are searching for a solution to this problem. Epistemic’s results are consistent with the state of the art, comparable to the best research developed worldwide.",
        line_2: "We then initiated the develop a wearable device for daily use that would warn about a seizure yet to come. Today we call this project Aurora. It is an audacious project that requires a lot of cutting-edge research and development work.",
        line_3: "We are today at the final stage. We will start the clinical testing phase soon, and then, finally, make the product available on the market.",
        line_4: "During our journey we had contact with many doctors and patients, and we were able to better understand their needs. Thereafter, we developed other products, with the help of technology, to help manage epilepsy.",
      },
      about_alert: {
        success: "Email successfully sent",
        fail: "Failed to try to send email",
        empty: "Fill in all fields"
      }
    },
    contact_page: {
      talk: "Talk",
      us: "With Us",
      when: "When",
      wish: "You wish",
      test: "Talk </br> With us",
      delete: {
        our_policy: "Our data deletion policy:",
        item_a: "a) Your personal data that may in any way identify you as an individual will be deleted within seven business days: Name, email, phone number, exact age and textual notes made in your diary.",
        item_b: "b) Other data will be kept in an anonymous way.",
        process: "About the account deletion process:",
        step_1: "Step 1: Send an email to contato@epistemic.com.br containing your Epistemic App username. It is a phone number that you use to log in. ",
        step_2: "Step 2: Wait for the confirmation email that your data has been deleted.",
      }
    }
  }
};

export default languages;