import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
  margin-top: 40px;
`;

export const IconButton = styled.button`
  margin-bottom: 10px;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
`