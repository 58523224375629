import React from 'react';
import { Container, MainEpistemicBlogText,  MainEpistemicBlogImage} from './styles';

export default function  BlogHelloImage() {
  return (
    <Container>
      <MainEpistemicBlogImage 
        src={"https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+blog/Blog.png"}
      />

      <MainEpistemicBlogText>
        Blog <br/> da <br/> Epistemic
      </MainEpistemicBlogText>
    </Container>
  );
}
