import React, {  useEffect, useState  } from 'react';
import { Container, Title, SecondInformation, Introduction, BlogPostDate, Tags, TagsGroup } from './styles';
import moment from 'moment';
import 'moment/locale/pt';
// import 'moment/locale/en';


export default function BlogInfo({ title, introduction, date, tags, color, text, expand, onClickTag }) {

  const [tagsToShow, setTags] = useState([]);

  useEffect(() => {
    moment().local('pt');

    let TagsArray = [];
    let tagName = "";
    let tagColor = "";
    try {
      const tagsSplited = tags.split(",");
      for (let i in tagsSplited) {
        switch(parseInt(tagsSplited[i])) {
          case 1: tagName = "Novidades"; tagColor="#d44c4c"; break;
          case 2: tagName = "Saúde"; tagColor="#4959c4"; break;
          case 3: tagName="Epilepsia"; tagColor="#8a1782";break;
          case 4: tagName="Vida";tagColor="#71d15c";break;
          case 5: tagName="Tecnológia"; tagColor="#4de4ff"; break;
        }
        let obj = { tagColor, tagName };
  
        TagsArray.push(obj);
      }

    } catch (e) {
      console.log(e);
    }
    
    setTags(TagsArray);
  }, [date, tags]);

  return (
    <Container>
    
      <Title color={color} >{title} </Title>
    
      <SecondInformation>
        <BlogPostDate>
          {moment(date).format('ll')}
        </BlogPostDate>
        <TagsGroup>
          {tagsToShow.map((item, index) => (
            <Tags 
              onClick={onClickTag} 
              color={item.tagColor} 
              key={index} >
                {" "}# {item.tagName}{" "}
            </Tags>
          ))}
        </TagsGroup>
      </SecondInformation>

      <Introduction 
        dangerouslySetInnerHTML={{ __html: introduction }} 
      />
    
    </Container>
  );
}
