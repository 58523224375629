import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  ChangeLanguageAction, 
  FooterColorAction, 
  ChangeHeaderColorAction,
  ChangeLanguageFeedbackAction
} from '../../actions';
import "./style.css";
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";


import First_Award from '../../img/First-Award.png';
import PremioFinep from '../../img/epistemic_finep.jpeg';
import PremioMapfre from '../../img/premios_mapfre.jpg';
import PremioLidFem from '../../img/paula_vencedora.jpeg';
import Aurora from '../../img/FotoAurora.jpg';
import Jornal_usp from '../../img/USP.PNG';
import CanalTech from '../../img/CanalTechImage.png'
import Second_Award from '../../img/Second-award.png';
import Catalisa_ICT from '../../img/Catalisa-ICT.png';
import Footer from '../../components/Footer';
import appNew_Image from '../../img/aplicativo-new-img.png';
import Aurora_Left from '../../img/pt_BR/Epistemic-Aurora-face-left.png';
import paula_draft from '../../img/paula_draft.jpg';

class News extends Component {
  componentDidMount() {
    const { logged } = this.props.reducer.author_infos;
    window.scrollTo(0, 0);
    this.props.FooterColorAction(false);
    this.props.ChangeHeaderColorAction(logged ? 6:5 );

    ReactGA.pageview("/news");
  }
  constructor(props) {
    super(props);
    this.state = { 
      main_image: 'https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+blog/Epitemic-Blog-inicial.png'
     }
  }
  render () { 
    const { language } = this.props.reducer.Language;
    const { show } = this.props.reducer.show_language_feedback;
    const { main_image } = this.state;
    return(
      <div class="Main-div"
        onClick={() => {
          try {
            if (show) {
              document.querySelector(".list-options").setAttribute("style", "animation: diminuindo 0.5s");
              setTimeout(() => {
                this.props.ChangeLanguageFeedbackAction(false);
              }, 400)
            }
          }catch(e) {
            console.log(e);
          }
        }}
      >

        <Helmet>
          <title>Epistemic - {language.header.news.toLowerCase()}</title>
        </Helmet>
        <div className="Main-Page-name-div" >
          <div className="Men-img-div" >
            <img loading='lazy' className="Men-img" src={main_image} alt={"Epistemic_Image"} />
          </div>
          <div className="Main-Page-title-div" >
            <p className="Main-Page-title" >{language.blog_page.last} <br /> {language.blog_page.updateds}</p>
          </div>
        </div>

        <div className="Second-Part-Page-Main-div">
          <div className="Row-Direction-div">

          <div className="Old-news-Main-div" >
              <a target="blank" href="https://www.projetodraft.com/imagine-e-se-fosse-possivel-prever-uma-crise-de-epilepsia-com-meia-hora-de-antecedencia-esse-e-um-dos-objetivos-da-epistemic/">
                <img loading='lazy' src={paula_draft} style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a 
                className="Old-news-Title" 
                target="blank" 
                href="https://www.projetodraft.com/imagine-e-se-fosse-possivel-prever-uma-crise-de-epilepsia-com-meia-hora-de-antecedencia-esse-e-um-dos-objetivos-da-epistemic/" > 
                  {language.blog_page.news_9_title}
                </a>
                <p className="Old-news-Text" > 
                {language.blog_page.news_9_explanation}
                </p>
              </div>
          </div>

          <div className="Old-news-Main-div" >
              <a target="blank" href="https://saudedigitalnews.com.br/28/03/2022/sistema-para-tratar-epilepsia-e-selecionado-por-programa-de-inovacao-do-sebrae/">
                <img loading='lazy' src={Catalisa_ICT} style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a 
                className="Old-news-Title" 
                target="blank" 
                href="https://saudedigitalnews.com.br/28/03/2022/sistema-para-tratar-epilepsia-e-selecionado-por-programa-de-inovacao-do-sebrae/" > 
                  {language.blog_page.news_7_title}
                </a>
                <p className="Old-news-Text" > 
                {language.blog_page.news_7_explanation}
                </p>
              </div>
          </div>

          <div className="Old-news-Main-div" >
              <a target="blank" href="https://www.fundacionmapfre.com.br/premios-bolsas/fundacion-mapfre-inovacao-social/projetos-finalistas/">
                <img loading='lazy' src={PremioMapfre} style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a 
                className="Old-news-Title" 
                target="blank" 
                href="https://www.fundacionmapfre.com.br/premios-bolsas/fundacion-mapfre-inovacao-social/projetos-finalistas/" > 
                  Epistemic está na final dos Prêmios Fundación MAPFRE à Inovação Social
                </a>
                <p className="Old-news-Text" > 
                  Três projetos, um para cada categoria dos Prêmios Fundación MAPFRE à Inovação Social, foram selecionados para defender o Brasil na grande final, que se tornou uma referência para inovadores sociais de todo o mundo. A Epistemic representará o país na categoria Melhora da saúde e tecnologia digital (e-health).
                </p>
              </div>
          </div>

          <div className="Old-news-Main-div" >
              <a target="blank" href="http://www.finep.gov.br/noticias/todas-noticias/6384-uma-das-vencedoras-do-premio-mulheres-inovadoras-2021-epistemic-ajuda-a-cuidar-da-epilepsia">
                <img loading='lazy' src={PremioFinep} style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a 
                className="Old-news-Title" 
                target="blank" 
                href="http://www.finep.gov.br/noticias/todas-noticias/6384-uma-das-vencedoras-do-premio-mulheres-inovadoras-2021-epistemic-ajuda-a-cuidar-da-epilepsia" > 
                  Uma das vencedoras do Prêmio Mulheres Inovadoras 2021, Epistemic ajuda a cuidar da epilepsia
                </a>
                <p className="Old-news-Text" > 
                Sobre o Programa Mulheres Inovadoras, Paula afirma que o diferencial máximo é a mentoria: "Geralmente, o mentor fica com você na hora das reuniões e é isso. Já no Mulheres Inovadoras, nossa mentora foi muito próxima, indo atrás de informações e nos dando ótimas dicas". Além disso...
                </p>
              </div>
          </div>
          
          <div className="Old-news-Main-div" >
              <a target="blank" href="https://www.linkedin.com/posts/inovaunicamp_conhe%C3%A7a-os-vencedores-do-pr%C3%AAmio-empreendedor-activity-6857064509706780672--Onj/">
                <img loading='lazy' src={PremioLidFem} style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a 
                className="Old-news-Title" 
                target="blank" 
                href="https://www.linkedin.com/posts/inovaunicamp_conhe%C3%A7a-os-vencedores-do-pr%C3%AAmio-empreendedor-activity-6857064509706780672--Onj/" > 
                  Vencedora do Prêmio Empreendedor da Unicamp 2021, na categoría Liderança Feminina
                </a>
                <p className="Old-news-Text" > 
                O caso de sucesso vencedor na categoria Liderança Feminina foi da empresa-filha Epistemic, de Paula Gomez. A startup desenvolve ferramentas para melhorar a vida de pessoas com epilepsia
                </p>
              </div>
          </div>

          <div className="Old-news-Main-div" >
              <a target="blank" href="https://revistapesquisa.fapesp.br/alerta-contra-crises-epilepticas/">
                <img loading='lazy' src={Aurora} style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a 
                className="Old-news-Title" 
                target="blank" 
                href="https://revistapesquisa.fapesp.br/alerta-contra-crises-epilepticas/" > 
                  Startup paulista desenvolve aparelho vestível para identificar crises com antecedência
                </a>
                <p className="Old-news-Text" > 
                  Procurando minimizar o sofrimento de pessoas com epilepsia, uma startup paulista desenvolveu um dispositivo portátil
                  para combater uma das principais características da doença: sua imprevisibilidade. 
                  Um aparelho vestível não invasivo, cujo objetivo é detectar e avisar os próprios afetados ou pessoas próximas de possíveis crises epilépticas com até 25 minutos
                  de antecedência, começará a ser testado...
                </p>
              </div>
          </div>

          <div className="Old-news-Main-div" >
              <a target="blank" href="https://jornal.usp.br/universidade/aplicativo-ajuda-pacientes-e-medicos-no-controle-da-epilepsia/">
                <img loading='lazy' src={Jornal_usp} style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a 
                className="Old-news-Title" 
                target="blank" 
                href="https://jornal.usp.br/universidade/aplicativo-ajuda-pacientes-e-medicos-no-controle-da-epilepsia/" > 
                  Startup da incubadora USP/Ipen-Cietec criou o app para fazer a gestão do quadro de saúde
                </a>
                <p className="Old-news-Text" > 
                De acordo com estudo realizado pela Organização Mundial da Saúde (OMS), há 65 milhões de pessoas com epilepsia no mundo, uma doença neurológica crônica caracterizada pela recorrência de crises epilépticas
                que podem se manifestar de diferentes formas dependendo do paciente.
                </p>
              </div>
            </div>

          <div className="Old-news-Main-div" >
              <a target="blank" href="https://pesquisaparainovacao.fapesp.br/startup_lanca_aplicativo_gratuito_que_auxilia_o_controle_da_epilepsia/1734">
                <img loading='lazy' src={"https://epistemic.com.br/imagens/FAPESP - Notícia.png"} style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a 
                className="Old-news-Title" 
                target="blank" 
                href="https://pesquisaparainovacao.fapesp.br/startup_lanca_aplicativo_gratuito_que_auxilia_o_controle_da_epilepsia/1734" > 
                  Startup lança aplicativo gratuito que auxilia o controle da epilepsia
                </a>
                <p className="Old-news-Text" > 
                  A startup Epistemic, residente na Incubadora USP/Ipen-Cietec e especializada na gestão para tratamento 
                  de epilepsia, lançou um aplicativo gratuito, integrado a uma plataforma digital para médicos, que auxilia 
                  o controle da epilepsia.  
                </p>
              </div>
            </div>

            <div className="Old-news-Main-div" >
              <a target="blank" href="https://www.saudebusiness.com/ti-e-inovao/aplicativo-indito-que-auxilia-o-controle-da-epilepsia-lanado-por-startup-do-cietec">
                <img loading='lazy' src={"https://epistemic.com.br/imagens/aplicativo-new-img.png"} style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a 
                className="Old-news-Title" 
                target="blank" 
                href="https://www.saudebusiness.com/ti-e-inovao/aplicativo-indito-que-auxilia-o-controle-da-epilepsia-lanado-por-startup-do-cietec" > 
                  Aplicativo inédito que auxilia o controle da epilepsia é lançado por startup do Cietec
                </a>
                <p className="Old-news-Text" > 
                  De acordo com o estudo realizado pela Organização Mundial da Saúde (OMS), há 65 milhões de pessoas com epilepsia no mundo e 1/3 dos pacientes não responde à medicação ou cirurgia.  
                </p>
              </div>
            </div>

            <div className="Old-news-Main-div" >
              <a target="blank" href="https://www.mobiletime.com.br/noticias/18/12/2020/aurora-e-epistemic-app-ajudam-paciente-com-epilepsia/">
                <img loading='lazy' src={'https://epistemic.com.br/imagens/mobile-time.png'} style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a 
                className="Old-news-Title" 
                target="blank" 
                href="https://www.mobiletime.com.br/noticias/18/12/2020/aurora-e-epistemic-app-ajudam-paciente-com-epilepsia/" > 
                  Aparelho com aplicativo poderá prever crise epiléptica 30 minutos antes 
                </a>
                <p className="Old-news-Text" > 
                A mãe de Paula Gomes possui 50 anos de estudo e trabalho na teoria do caos. Física, recentemente, ela passou a analisar os caóticos exames de eletroencefalogramas e descobriu uma maneira de detectar crises em pessoas com epilepsia.
                </p>
              </div>
            </div>

            <div className="Old-news-Main-div" >
              <a target="blank" href="https://canaltech.com.br/saude/novo-wearable-avisa-paciente-quando-uma-crise-epileptica-estiver-por-vir-176370/">
                <img loading='lazy' src={"https://epistemic.com.br/imagens/CanalTechImage.png"} style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a 
                className="Old-news-Title" 
                target="blank" 
                href="https://canaltech.com.br/saude/novo-wearable-avisa-paciente-quando-uma-crise-epileptica-estiver-por-vir-176370/" > 
                Novo wearable avisa paciente quando uma crise epiléptica estiver por vir 
                </a>
                <p className="Old-news-Text" > 
                Segundo a Organização Mundial da Saúde (OMS), a epilepsia é considerada a mais comum e a mais grave das condições neurológicas crônicas. 
                </p>
              </div>
            </div>

            <div className="Old-news-Main-div" >
              <a target="blank" href="https://revistapegn.globo.com/Startups/noticia/2018/04/brasileira-ganha-premio-de-empreendedorismo-feminino-da-cartier.html">
                <img loading='lazy' src={First_Award} style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a className="Old-news-Title" target="blank" href="https://revistapegn.globo.com/Startups/noticia/2018/04/brasileira-ganha-premio-de-empreendedorismo-feminino-da-cartier.html" > {language.blog_page.news_1_title} </a>
                <p className="Old-news-Text" >{language.blog_page.news_1_explanation}</p>
              </div>
            </div>

            <div className="Old-news-Main-div" >
              <a target="blank" href="https://valor.globo.com/carreira/recursos-humanos/noticia/2018/08/01/cartier-vai-premiar-mulheres-empreendedoras.ghtml">
                <img loading='lazy' src='https://epistemic.com.br/imagens/valor-cartier.png' style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a className="Old-news-Title" target="blank" href="https://valor.globo.com/carreira/recursos-humanos/noticia/2018/08/01/cartier-vai-premiar-mulheres-empreendedoras.ghtml" > {language.blog_page.news_2_title} </a>
                <p className="Old-news-Text" >{language.blog_page.news_2_explanation}</p>
              </div>
            </div>

            {/* <div className="Old-news-Main-div" >
              <a target="blank" href="http://www.futuraplay.org/video/dispositivo-epilepsia/432967/">
                <img loading='lazy' src='https://epistemic.com.br/imagens/futura.jpg' style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a className="Old-news-Title" target="blank" href="http://www.futuraplay.org/video/dispositivo-epilepsia/432967/" >{language.blog_page.news_3_title}  </a>
                <p className="Old-news-Text" >{language.blog_page.news_3_explanation}</p>
              </div>
            </div> */}

            <div className="Old-news-Main-div" >
              <a target="blank" href="https://www.cietec.org.br/ganhamos-brasileira-paula-gomez-recebeu-premio-da-cartier-de-empreendedorismo-feminino/">
                <img loading='lazy' src='https://epistemic.com.br/imagens/cietec.png' style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a className="Old-news-Title" target="blank" href="https://www.cietec.org.br/ganhamos-brasileira-paula-gomez-recebeu-premio-da-cartier-de-empreendedorismo-feminino/" > {language.blog_page.news_4_title} </a>
                <p className="Old-news-Text" >{language.blog_page.news_4_explanation}</p>
              </div>
            </div>

            <div className="Old-news-Main-div" >
              <a target="blank" href="https://www.epilepsy.com/make-difference/research-and-new-therapies/innovation/epilepsy-therapy-project/shark-tank-0">
                <img loading='lazy' src='https://epistemic.com.br/imagens/shark-tank-2017.png' style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a className="Old-news-Title" target="blank" href="https://www.epilepsy.com/make-difference/research-and-new-therapies/innovation/epilepsy-therapy-project/shark-tank-0" > {language.blog_page.news_5_title} </a>
                <p className="Old-news-Text" >{language.blog_page.news_5_explanation}</p>
              </div>
            </div>

            <div className="Old-news-Main-div" >
              <a target="blank" href="https://pme.estadao.com.br/noticias/geral,por-inovacao-hospitais-buscam-aproximacao-com-startups,6111p">
                <img loading='lazy' src='https://epistemic.com.br/imagens/materia-estado.png' style={{ width: "90%", aspectRatio: 1 }}  />
              </a>
              <div className="Old-news-info-div" >
                <a className="Old-news-Title" target="blank" href="https://pme.estadao.com.br/noticias/geral,por-inovacao-hospitais-buscam-aproximacao-com-startups,6111p" > Epistemic on more news </a>
                <p className="Old-news-Text" >The newspaper O Estado de São Paulo also reported Epistemic's reception of the award "Prêmio Empreenda Saúde" (Undertake Health Award) from the Sírio-Libanês Hospital and the Everis Foundation.</p>
              </div>
            </div>
            <div className="Left-video-div" >
              <p className="Old-news-Video-Title" >Entrevista sobre o prêmio "Premio Empreende Saúde" recebido pela Epistemic em 2017</p>
                <iframe 
                  className="video-news"
                  src="https://www.youtube.com/embed/sNjJREql3vk" 
                  frameborder="0" 
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen
                />
            </div>
         </div>

          <div className="News-new-Main-div">
            <div className="Right-video-div" style={{ marginTop: 0  }}>
              <p className="New-news-Title" > Epistemic nos Prêmios Fundación MAPFRE à Inovação Social</p>
              <iframe 
                className="video-news"
                src="https://www.youtube.com/embed/et9QHihBIyA" 
                frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
              />
            </div>

            <div className="news-div" >
              <a target="blank" href={
                language.language === "pt_BR"
                  ? "https://www-infosalus-com.translate.goog/salud-investigacion/noticia-epistemic-diadema-predice-crisis-epilepticas-finalista-premios-fundacion-mapfre-innovacion-social-20220401172149.html?_x_tr_sl=es&_x_tr_tl=pt&_x_tr_hl=pt-BR&_x_tr_pto=wapp"
                  : "https://www-infosalus-com.translate.goog/salud-investigacion/noticia-epistemic-diadema-predice-crisis-epilepticas-finalista-premios-fundacion-mapfre-innovacion-social-20220401172149.html?_x_tr_sl=es&_x_tr_tl=en&_x_tr_hl=pt-BR&_x_tr_pto=wapp"
                }>
                <img loading='lazy' src={Aurora_Left} style={{ width: "30%", aspectRatio: 1, marginTop: 20, marginLeft: "33%" }} />
              </a>
              <div className="New-news-info-div" >
                <a className="New-news-Title" target="blank" href="https://www.infosalus.com/salud-investigacion/noticia-epistemic-diadema-predice-crisis-epilepticas-finalista-premios-fundacion-mapfre-innovacion-social-20220401172149.html" >
                  {language.blog_page.news_8_title}
                </a>
                <p className="New-news-Text" >{language.blog_page.news_8_explanation}</p>
              </div>
            </div>

            <div className="news-div" >
              <a target="blank" href="https://revistamarieclaire.globo.com/Noticias/noticia/2018/04/ganhamos-brasileira-recebe-premio-da-cartier-de-empreendedorismo-feminino.html">
                <img loading='lazy' src={Second_Award} style={{ width: "90%", aspectRatio: 1, marginTop: 20  }}  />
              </a>
              <div className="New-news-info-div" >
                <a className="New-news-Title" target="blank" href="https://revistamarieclaire.globo.com/Noticias/noticia/2018/04/ganhamos-brasileira-recebe-premio-da-cartier-de-empreendedorismo-feminino.html" >{language.blog_page.news_6_title} </a>
                <p className="New-news-Text" >{language.blog_page.news_6_explanation}</p>
              </div>
            </div>

            <div className="Right-video-div">
              <p className="New-news-Title" > Apresentação da Epistemic no congresso de 2017 na Epilepsy Foundation of America</p>
              <iframe 
                className="video-news"
                src="https://www.youtube.com/embed/HHHOfgdKqnQ" 
                frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
              />
            </div>

            <div className="Right-video-div">
              <p className="New-news-Title" > Epistemic by Cartier</p>
              <iframe 
                className="video-news"
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/cBGRtxetFmc" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
              </iframe>
            </div>
          </div>
        </div>

        <div className="Footer-div" >
          <Footer black={false} />
        </div>
      </div>
      
    )
  }
}

const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ 
    ChangeLanguageAction, 
    FooterColorAction, 
    ChangeHeaderColorAction, 
    ChangeLanguageFeedbackAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(News);