import React, { Component } from 'react';
import Footer from '../../components/Footer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  ChangeLanguageAction, 
  FooterColorAction, 
  ChangeHeaderColorAction, 
  ChangeLanguageFeedbackAction 
} from '../../actions';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Parallax } from 'react-scroll-parallax';

import "./styles.css";

import Aurora_Image from "../../img/pt_BR/Epistemic-Aurora-Imagem.png";
import Aurora_Face_Right from "../../img/pt_BR/Epistemic-Aurora-face-right.png";
import Aurora_Face_Left from "../../img/pt_BR/Epistemic-Aurora-face-left.png";


class Aurora extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.FooterColorAction(true);
    this.props.ChangeHeaderColorAction(3);

    ReactGA.pageview("/auroa");
  }
  render() {
    const { language } = this.props.reducer.Language;
    const { show } = this.props.reducer.show_language_feedback;
    return (
      <div className="Aurora-Page" 
        onClick={() => {
          try {
            if (show) {
              document.querySelector(".list-options").setAttribute("style", "animation: diminuindo 0.5s");
              setTimeout(() => {
                this.props.ChangeLanguageFeedbackAction(false);
              }, 400)
            }
          }catch(e) {
            console.log(e);
          }
        }}
      >

        <Helmet>
          <title>Epistemic - {language.header.aurora.toLowerCase()}</title>
        </Helmet>
        <div className="aurora-welcome-div">
          <p className="aurora-welcome-developtment-title">Em desenvolvimento</p>
          <h1 className="aurora-welcome-title" >AURORA</h1>
          <h2 className="aurora-welcome-subtitle">Vista-se para o futuro</h2>
          <p className="aurora-welcome-explanation">Um projeto do amanha que te oferece ajuda antes mesmo que voce precise dela.</p>
        </div>
       
        <div className="Second-Main-div" >
          <div className="Aurora-img-div" >
            <Parallax className="custom-class" y={[-10, 10]} tagOuter="figure">
              <img loading='lazy' className="Aurora-img" src={Aurora_Image} alt={"Aurora_Iamge"} />
            </Parallax>
          </div>
          <div className="Epistemic-Aurora-Title-div" >
            <p className="Epistemic-Aurora-Title" >{language.aurora_page.epistemic} <b>{language.aurora_page.page_title}</b></p>
          </div>
          <div className="Epistemic-Aurora-Explanation-div" >
            <p className="Epistemic-Aurora-Explanation" >{language.aurora_page.epistemic_explanation}</p>
          </div>
          <div className="Epistemic-Aurora-Face-div">
            <div className="Epistemic-Aurora-Face-1-main-div" >
              <img loading='lazy' className="Epistemic-Aurora-Face-1" src={Aurora_Face_Left} alt={"Aurora-Face-Left"} />
              <h1 className="legend-face" >{language.aurora_page.daily_use}</h1>
            </div>
            <div className="Epistemic-Aurora-Face-1-main-div">
              <img loading='lazy' className="Epistemic-Aurora-Face-2" src={Aurora_Face_Right} alt={"Aurora-Face-Right"} />
              <h1 className="legend-face" >{language.aurora_page.night_use}</h1>
            </div>
          </div>
          <div className="Epistemic-Aurora-Third-Title-div">
            <p className="Epistemic-Aurora-Third-Title" >{language.aurora_page.practicality_title}</p>
          </div>
          <div className="Epistemic-Aurora-Third-Explanation-div" >
            <p className="Epistemic-Aurora-Third-Explanation" >{language.aurora_page.practicality_explanation_1}</p>
            <p className="Epistemic-Aurora-Third-Explanation" >{language.aurora_page.practicality_explanation_2}</p>
        </div>

          <div className="Epistemic-Aurora-Last-Updates-div">
            <p className="Epistemic-Aurora-Last-Updates-Title" >{language.aurora_page.innovative_project_title}</p>

            <div className="Epistemic-Aurora-Project-Text-div" >
              <div className="Epistemic-Aurora-Project-text-main-div" >
                <div className="Epistemic-Aurora-devider-text-div" >
                  <div className="Epistemic-text" >
                    {language.aurora_page.innovative_project_explanation_1} 
                    <br/>
                    {language.aurora_page.innovative_project_explanation_2}
                    <br/>
                    {language.aurora_page.innovative_project_explanation_3}
                    <br/>
                    {language.aurora_page.innovative_project_explanation_4}
                    <br/>
                    {language.aurora_page.innovative_project_explanation_5}
                    <br/>
                    {language.aurora_page.innovative_project_explanation_6}
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
        <div className="Footer-div-aurora" >
          <Footer black={true} aurora={true} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    ChangeLanguageAction, 
    FooterColorAction, 
    ChangeHeaderColorAction, 
    ChangeLanguageFeedbackAction}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Aurora);