import React from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import { 
  Main, 
  App, 
  About, 
  Aurora, 
  News, 
  New_Blog, 
  blog_text, 
  Author_Login, 
  author_home,
  New_post,
  Contact,
  Blog,
  CreateAndEditBlogPost,
  Research
} from './pages';
import Header from './components/Header';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

const Routes = () => (
  <HashRouter >
    <Header />
    <Switch>
      <Route exact path="/" component={Main} />
      <Route path="/app" component={App} />
      <Route exact path="/about_epistemic" component={About} />
      <Route path="/aurora" component={Aurora} />
      <Route path="/news_epistemic" component={News} />
      <Route path="/blog_main_page" component={Blog} />
      <Route path="/blog_text/:name_id/:creating" component={blog_text} />
      <Route path="/author_login_edit_mode" component={Author_Login} />
      <Route path="/author_home" component={author_home} />
      <Route path="/new_post/:name_id/:edit" component={New_post} />
      <Route path="/Contact" component={Contact} />
      <Route path="/create_new_post" component={CreateAndEditBlogPost} />
      <Route path="/pesquisa" component={Research} /> 
    </Switch>
  </HashRouter>
);

export default Routes;