import React, { useEffect, useState } from 'react';
import { Container, 
  HeaderPosts, 
  AddPostButton, 
  HeaderTitle, 
  TablePosts, 
  TrTitle, 
  ThTitle, 
  CheckBoxPost, 
  ThCheckBox, 
  TrRows, 
  ThRows,
  PostOptions,
  DeletePost,
  ThPostName
} from './styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SaveAuthorInfosAction, ChangeHeaderColorAction } from '../../actions';
import { payload } from '../../services/payload';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Modal from 'react-modal';
import DatePicker from 'react-date-picker';
import { Redirect } from 'react-router-dom';
import webconsole from '../../config/degub';

let tags = []; 
let updateHeaderColor = 1;

const Author_home = (props) => {

  const [posts, setPosts] = useState([]);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if(updateHeaderColor === 1) {
      props.ChangeHeaderColorAction(9);
      updateHeaderColor = 2;
    }

    const GetPosts = async () => {
      const response = await api.get(payload.endpoints.user, {
        params: {
          action: "get_all_posts"
        }
      });

      if(response.status === 200) {
        setPosts(response.data.data.posts);
      } else {
        alert("Algo deu errado");
      }
    }

    GetPosts();
  }, [props])
  
  return(
    <Container>
    
      <HeaderPosts> 
        
        <HeaderTitle>
          Posts
        </HeaderTitle>
        
        <Link 
          to={{
            pathname: `/create_new_post/`,
        }}>
          <AddPostButton>
            Adicionar novo
          </AddPostButton>
        </Link>
      
      </HeaderPosts>

      {/* <PostOptions>
        <DeletePost
          disabled={true}
          onClick={() => {
            alert("Logo logo poderemos deletar alguma coisa!");
          }}
          disabled={false}>
          Deletar posts
        </DeletePost>
      </PostOptions> */}

      <TablePosts type="sortable">
      
        <TrTitle>
          {/* <ThTitle /> */}
          <ThTitle>
            Titulo
          </ThTitle>
          <ThTitle>
            Autor
          </ThTitle>
          <ThTitle>
            Tag
          </ThTitle>
          <ThTitle>
            Data
          </ThTitle>
          <ThTitle>
            Data para expirar
          </ThTitle>
        </TrTitle>

        {posts.map((item, index) => (
          <TrRows
            key={index}
          >
            <ThCheckBox key={index}>
              <CheckBoxPost 
                key={item.id} 
                value={item.id}
                type="checkbox" 
                checked={check && item.id === index}
                onChange={(event) => {
                  // if(index === item.id)
                  // webconsole.log(`${item.id}`);
                  webconsole.log(`${event.target.value }`);
                  webconsole.log(`${index}`);
                  // if(){

                  // }
                  // if(item.id === parseInt(event.target.value)) {
                  //   webconsole.log(`Estou entrando aqui!`);
                  //   setCheck(!check);
                  // }
                  
                }}
              />
            </ThCheckBox >

            <ThPostName key={index}>
              <Link
                style={{ textDecoration: 'none', color: "#DF7400"}}
                key={index}
                to={{
                  pathname: `/create_new_post/${item.name_id}`,
                  state: item
                }}
              >
                {item.title}
              </Link>
            </ThPostName>
            <ThRows key={index}>{item.author}</ThRows>
            <ThRows key={index}>{renderTags(item.tags)}</ThRows>
            <ThRows key={index}>{showDates(item.post_on)}</ThRows>
            <ThRows key={index}>{showDates(item.post_end_on)}</ThRows>
          </TrRows>
        ))}

      </TablePosts>
      
    </Container>
  )

  function renderTags(tags) {
    let tagArray = tags.split(",");
    let tagName = "";
    let tagColor = "";

    for(let i in tagArray) {
      switch(parseInt(tagArray[i])) {
        case 1: tagName+= "Novidades, "; break;
        case 2: tagName+= "Saúde, "; break;
        case 3: tagName+="Epilepsia, "; break;
        case 4: tagName+="Vida, "; break;
        case 5: tagName+="Tecnologia, "; break;
      }
    }
    return tagName;
  }

  function showDates(date) {
    const date_formated = moment(date).format("DD/MM/YYYY");

    if(date_formated !== "Invalid date") {
      return date_formated;
    } 

    return "-";
  }
}

const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({SaveAuthorInfosAction, ChangeHeaderColorAction}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Author_home);