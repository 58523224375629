import { 
  CHANGE_LANGUAGE, 
  CHANGE_HEADER_COLOR, 
  CHANGE_FOOTER_COLOR, 
  CHANGE_FOOTER_COLOR_AURORA, 
  CHANGE_LANGUAGE_FEED_BACK,
  SAVE_AUTHOR_INFOS
} from './actionsTypes';

export const ChangeLanguageAction = language => ({
  type: CHANGE_LANGUAGE,
  language: language
});

export const ChangeHeaderColorAction = _index => ({
  type: CHANGE_HEADER_COLOR,
  index: _index
});

export const FooterColorAction = black => ({
  type: CHANGE_FOOTER_COLOR,
  black: black
})

export const FooterColorAurora = bool => ({
  type: CHANGE_FOOTER_COLOR_AURORA,
  color: bool
})

export const ChangeLanguageFeedbackAction = bool => ({
  type: CHANGE_LANGUAGE_FEED_BACK,
  show: bool
})

export const SaveAuthorInfosAction = (infos, logged) => ({
  type: SAVE_AUTHOR_INFOS,
  infos: infos,
  logged: logged
})