import { CHANGE_FOOTER_COLOR } from '../actions/actionsTypes';

const initialState = {
  black: false
};

export const Footer_Color_Reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_FOOTER_COLOR:
      return {
        ...state,
        black: action.black
      }

    default:
      return state;
  }
}