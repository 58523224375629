import React, { Component } from 'react';
import "./styles.css";

import fapesp_white  from "../../img/fapesp-white.png";
import fapesp_black from "../../img/fapesp-black.png"
import cietec  from "../../img/logo-cietec.png";
import cietec_branco from "../../img/cietec_logo_i&e_branco.png";
import CNPQ_Color from "../../img/cnpq-logo-color.png";
import CNPQ_White from "../../img/cnpq-logo-white.png";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FooterColorAction, ChangeHeaderColorAction } from '../../actions';

import { Link } from 'react-router-dom';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

class Footer extends Component  {
  constructor (props) {
    super(props);
  }
  render() {
    const { language } = this.props.Reducer.Language;
    const { black } = this.props.Reducer.footer_color;

    return (
    <footer className={this.renderFooterColors() }>
        <div className="coloum-div">
          <p className={!black ? "footer_text_black" : "footer_text_white" } >{language.footer.sing}</p>
          <Link 
            className={!black ? "footer_exText_black" : "footer_exText_white" } 
            onClick={() => {
              this.props.ChangeHeaderColorAction(2);
            }} to={"/app"} >
            EpistemicApp
          </Link>
        </div>

        <div className="coloum-div">
          <p className={!black ? "footer_text_black" : "footer_text_white"} >{language.footer.service}</p>
          <div>
            <a
              className={!black ? "footer_exText_black" : "footer_exText_white" }
              href="https://www.epistemic.com.br/physicians_home/"
              target="_blank"
              rel="noopener noreferrer"
            >
              EpistemicWeb
            </a>
          </div>
          {/* <Link 
            className={!black ? "footer_exText_black" : "footer_exText_white" } 
            onClick={() => {
              this.props.ChangeHeaderColorAction(5);
            }} to={"/blog"} >
            EpistemicWebBlog
          </Link> */}
        </div>
        
        <div className="coloum-div">
          <p  className={!black ? "footer_text_black" : "footer_text_white"} >{language.footer.contact}</p>
          {/* <p className={!black ? "footer_exText_black" : "footer_exText_white" }></p> */}
          <Link 
            className={!black ? "footer_exText_black" : "footer_exText_white" } 
            to={"/Contact"}
            onClick={() => {
              this.props.ChangeHeaderColorAction(6);
            }} >
            {language.footer.email}
          </Link>
          <p>
            +55 (11) 3039-8311
          </p>
        </div>
        
        <div className="coloum-div">
          <p className={!black ? "footer_text_black" : "footer_text_white"} >{language.footer.developed_by}</p>
          <p className={!black ? "footer_exText_black" : "footer_exText_white" }>Epistemic ®</p>
          <p className={!black ? "footer_exText_black" : "footer_exText_white" }>Razão Social: Gomez & Gomez Ltda. - ME</p>
        </div>
        
        <div className="coloum-div">
          <p className={!black ? "footer_text_black" : "footer_text_white"}> {language.footer.incubated_in} </p>
          <a target="blank" href="https://www.cietec.org.br/">
            <img className="Logo" src={black ? cietec_branco : cietec} alt="Logo-cietec" />
          </a>
        </div>

        <div className="coloum-div">
          <p className={!black ? "footer_text_black" : "footer_text_white"}> {language.footer.financed_by} </p>
          <div className="coloum-div-imgs">
            <a target="blank" href="http://www.fapesp.br/">
              <img className="Logo" src={black ? fapesp_white : fapesp_black } alt="Logo-fapesp" />
            </a>

            <a target="blank" href="http://www.cnpq.br/">
              <img className="Logo" src={!black ? CNPQ_Color : CNPQ_White} alt="Logo-CNPQ" />
            </a>
          </div>
        </div>
        
      </footer>
    )
  }
  renderFooterColors() {
    const { color } = this.props.Reducer.aurora_footer;
    const { black } = this.props.Reducer.footer_color;
    if (color) {
      return "footer_black_aurora";
    } else if (black) {
      return "footer_black";
    } else {
      return "footer_white";
    }
  }
}
const mapStateToProps = store => ({
  Reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ FooterColorAction, ChangeHeaderColorAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);