import React, { Component } from 'react';
import "./styles.css";
import HeaderButton from '../HeaderButton';
import { Link } from 'react-router-dom';
import { FaList } from 'react-icons/fa';

import logo_branco from "../../img/pt_BR/logo_branco_horizontal.png";
import logo_roxo from "../../img/pt_BR/logo_roxo_horizontal.png";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChangeLanguageAction, ChangeHeaderColorAction, FooterColorAurora } from '../../actions';

import Country_Selector from "../Country_Selector";

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

let show;
class Header extends Component {
  state = { aurora_background: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+aurora/Epitemic-Aurora-background.png" }
  render() {
    const { language } = this.props.reducer.Language;
    const { index } = this.props.reducer.index;
    const { logged } = this.props.reducer.author_infos;
    return (
      <div >
        {console.log(index)}
        <div className="side_bar" >
          <ul>
            <li>
              <Link className="item_side_bar" to={'/'} onClick={() => {
                  this.props.ChangeHeaderColorAction(1);
                  this.props.FooterColorAurora(false);
                  document.querySelector(".side_bar").setAttribute("style", "left: -50%;display: grid; animation-name: closeSisde_bar;");
                }}>
                  {language.header.home}
              </Link>
              </li>

            <li>
              <Link className="item_side_bar" to={'/app'} onClick={() => {
                this.props.ChangeHeaderColorAction(2);
                this.props.FooterColorAurora(false);
                document.querySelector(".side_bar").setAttribute("style", "left: -50%;display: grid; animation-name: closeSisde_bar;");
              }}>
                {language.header.app}
              </Link>
            </li>

            <li>
              <Link className="item_side_bar" to={'/aurora'} onClick={() => {
                this.props.ChangeHeaderColorAction(3);
                this.props.FooterColorAurora(true);
                document.querySelector(".side_bar").setAttribute("style", "left: -50%;display: grid; animation-name: closeSisde_bar;");
              }}>
                {language.header.aurora}
              </Link>
            </li>

            <li>
              <a 
                className="item_side_bar" 
                href={`https://www.epistemic.com.br/physicians_home/`}
              >
                {language.header.doctors}
              </a>
            </li>

            <li>
              <Link className="item_side_bar" to={'/news_epistemic'} onClick={() => {
                this.props.ChangeHeaderColorAction(5);
                this.props.FooterColorAurora(false);
                document.querySelector(".side_bar").setAttribute("style", "left: -50%;display: grid; animation-name: closeSisde_bar;");
              }}>
                {language.header.news}
              </Link>
            </li>

            <li>
              <Link className="item_side_bar" to={'/about_epistemic'} onClick={() => {
                this.props.ChangeHeaderColorAction(6);
                this.props.FooterColorAurora(false);
                document.querySelector(".side_bar").setAttribute("style", "left: -50%;display: grid; animation-name: closeSisde_bar;");
              }}>
                {language.header.about}
              </Link>
            </li>


            <li>
              <Link className="item_side_bar" to={'/contact'} onClick={() => {
                this.props.ChangeHeaderColorAction(6);
                this.props.FooterColorAurora(false);
                document.querySelector(".side_bar").setAttribute("style", "left: -50%;display: grid; animation-name: closeSisde_bar;");
              }}>
                {language.header.contact}
              </Link>
            </li>

          </ul>
        </div>

        <header className="nav-header" style={{
            backgroundColor: this.RenderBackGroundHeader(index)
          }} >
          <img className="logo-img" src={this.showRightLogo(index)} alt="logo" />
          {this.renderBackgroundImageAurora(index)}
            <div style={{display: "flex", flexDirection: "row",  }} >
              <button className="Button-Side-Bar" onClick={() => {
                  show = !show;
                  if(show){
                    document.querySelector(".side_bar").setAttribute("style", "left: 0%; display: grid"); 
                  } else {
                    document.querySelector(".side_bar").setAttribute("style", "left: -50%;display: grid; animation-name: closeSisde_bar;");
                  }
                }} >
                  <FaList color={this.renderFaListColor(index)} size={20} />
              </button>
                <div className="Flags_div_low-resolution" >
                  <Country_Selector />
                </div>
              </div>

            <nav className="nav-buttons">
              <HeaderButton 
                className="Header-button" 
                title={language.header.home} 
                onClick={() => {
                  this.props.ChangeHeaderColorAction(1);
                  this.props.FooterColorAurora(false);
                }} 
                feedback={index === 1}
                width={90}
                page={"/"}
                section={index}
              />
              <HeaderButton 
                title={language.header.app} 
                onClick={() => {
                  this.props.ChangeHeaderColorAction(2);
                  this.props.FooterColorAurora(false);
                }} 
                feedback={index === 2} 
                width={90}
                page={"/app"}
                section={index}
              />

              <HeaderButton 
                title={language.header.aurora} 
                onClick={() => {
                  this.props.ChangeHeaderColorAction(3);
                  this.props.FooterColorAurora(true);
                }} 
                feedback={index === 3} 
                width={90}
                page={"/aurora"}
                section={index}
              />

              <HeaderButton 
                title={language.header.doctors} 
                onClick={() => {
                  this.props.ChangeHeaderColorAction(4);
                  this.props.FooterColorAurora(false);
                }} 
                feedback={index === 4} 
                width={110}
                page={"/"}
                section={index}
                doctors={true}
                language={language.language}
              />

              <HeaderButton 
                title={language.header.news} 
                onClick={() => {
                  this.props.ChangeHeaderColorAction(5);
                  this.props.FooterColorAurora(false);
                }} 
                feedback={index === 5} 
                width={110}
                page={"/news_epistemic"}
                section={index}
              />
              
              {/* <HeaderButton 
                title={language.header.blog} 
                onClick={() => {
                  this.props.ChangeHeaderColorAction(6);
                  this.props.FooterColorAurora(false);
                }} 
                feedback={index === 6} 
                width={110}
                page={"/blog_main_page"}
                section={index}
              /> */}

              <HeaderButton 
                title={language.header.about} 
                onClick={() => {
                  this.props.ChangeHeaderColorAction(7);
                  this.props.FooterColorAurora(false);
                }} 
                feedback={index === 7} 
                width={90}
                page={"/about_epistemic"}
                section={index}
              />

              <HeaderButton 
                title={language.header.contact} 
                onClick={() => {
                  this.props.ChangeHeaderColorAction(8);
                  this.props.FooterColorAurora(false);
                }} 
                feedback={index === 8} 
                width={90}
                page={"/Contact"}
                section={index}
              />


              {logged ? 
                (<HeaderButton 
                  title={"Editor"} 
                  onClick={() => {
                    this.props.ChangeHeaderColorAction(9);
                    this.props.FooterColorAurora(false);
                  }} 
                  feedback={index === 9} 
                  width={90}
                  page={"/author_home"}
                  section={index}
                /> )
              : 
                <div />
              } 

              <div className="Flags_div_high-resolution" >
                <Country_Selector />
              </div>
          </nav>
        </header>
      </div>
    );
  }
  RenderBackGroundHeader(_index) {
    switch(_index) {
      case 1: return "#5F2F85";
      case 2: return "black";
      case 3: return "transparent";
      case 4: return "#5F2F85";
      case 5: return "white";
      case 6: return "transparent";
      case 7: return "white";
      case 8: return "white";
      case 9: return "white";
    }
  }
  showRightLogo(_index) {
    if (_index === 5 || _index === 7 || _index === 8) {
      return logo_roxo;
    } 
    return logo_branco;
  }
  renderFaListColor(_index) {
    if(_index === 5 || _index === 6 || _index === 7 || _index === 8) {
      return "black";
    }
    return "white";
  }
  renderBackgroundImageAurora(_index) {
    const { aurora_background } = this.state;
    if (_index === 3) {
      return(
        <img loading='lazy' className="aurora-background" src={aurora_background} alt="IMG_background" />
      )
    }
  }
}


const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ChangeLanguageAction, ChangeHeaderColorAction, FooterColorAurora}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);

