import { CHANGE_LANGUAGE } from '../actions/actionsTypes';
import language from "../languages";

const initialState = {
  language: language.pt_BR
};

export const LanguageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: language[action.language]
      }

    default:
      return state;
  }
}