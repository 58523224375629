import stage from './stage';

const webconsole = {
  log(_msg) {
    if(stage.debug){
      console.log(_msg);
    }
  },
  warn(_msg) {
    if(stage.debug) {
      console.warn(_msg)
    }
  },
  error(_msg) {
    if(stage.debug){
      console.error(_msg);
    }
  }
}

export default webconsole;