import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SaveAuthorInfosAction } from '../../actions';
import RichTextEditor from 'react-rte';
import DatePicker from 'react-date-picker';
import { FaRegEye } from "react-icons/fa";
import { Link } from 'react-router-dom';
import api from '../../services/api';
import moment from 'moment';
import { payload } from '../../services/payload';
import Modal from 'react-modal';
import { Redirect } from 'react-router-dom';

import "./style.css";

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    borderRadius: 20,
  }
};
class New_post extends Component {

  state = {
    value: RichTextEditor.createEmptyValue(),
    image_url: "",
    schedule_bool: false,
    schedule_date: "",
    eye_color: "black",
    topic: 1,
    title: "",
    enable_post: true,
    done_text: "Postar",
    page_title: "Novo Post",
    delete_post: false,
    delete_modal: false,
    post_id: null,
    edit_post: false,
    loading: false,
    blog_text: false,
    get_info_loading: false,
    schedule_type: 4,
    schedule_end_data: "",
    name_id: null
  }

  constructor(props){ 
    super(props);
  }

  onChange = (value) => {
    this.setState({value, blog_text: true});
    if (this.props.onChange) {
      this.props.onChange(
        value.toString('html')
      );
    }
  };

  componentDidMount() {
    const { name_id, edit } = this.props.match.params;

    if (edit === "false") {
      this.setState({ 
        delete_post: false, 
        done_text: "Postar", 
        page_title: "Novo Post", 
        edit_post: false 
      });
    } else {
      this.setState({ 
        delete_post: true, 
        done_text: "Salvar", 
        page_title: "Editar Post", 
        edit_post: true,
      });
      this.getPostDetails(name_id);
    }
  }

  render(){
    const { 
      image_url, 
      eye_color, 
      topic, 
      title,
      done_text,
      page_title,
      delete_post,
      delete_modal,
      post_id,
      loading,
      schedule_type,
      name_id,
      edit_post
    } = this.state;
    const { infos, logged } = this.props.reducer.author_infos;
    if ( 1 > 2 /*!logged */) {
      return <Redirect to="/author_login_edit_mode" />
    }
    return(
      <div className="Main_div_new_post">
        <Modal
          isOpen={delete_modal}
          style={customStyles}
          contentLabel="Delete Modal"
        >
           <div>
            <h2>Deseja realmente excluir esta publição ?</h2>
            <div className="delete_button_div">
              <button 
                className="delete_yes_button"
                onClick={() => {
                  this.Delete_Post(post_id);
                  this.setState({ delete_modal: false });
                  document.getElementById("react_editor_opacity").setAttribute("style", "opacity: 1;");
                }}
                >
                  Sim
                </button>
              <button 
                className="delete_no_button"
                onClick={() => {
                  this.setState({ delete_modal: false });
                  document.getElementById("react_editor_opacity").setAttribute("style", "opacity: 1;");
                }}
              >
                Não
              </button>
            </div>
          </div>
        </Modal>

        <div className="Header_posts">
          <h2 className="page_title">
            {page_title}
          </h2>
          <div className="buttons_header_div">
            <button 
              disabled={loading}
              className="post_button"
              onClick={() => {
                this.validadeValues();
              }}
              >
                {loading? (
                  <i className="fa fa-refresh fa-spin" ></i>
                ):
                  <p>{done_text}</p>
                }
              </button>
            {delete_post? (
              <button 
                className="delete_button" 
                onClick={() => {
                  this.setState({ delete_modal: true });
                  document.getElementById("react_editor_opacity").setAttribute("style", "opacity: 0.5;");
                }}
              >Deletar</button>
            ): (
              <div/>
            )}
            <Link className="cancel_button" to={"/author_home"}>Cancelar </Link>
          </div>
        </div>

        <div className="divider_div" />

        <div className="main_form_div" >

          <div className="create_blog_post_div">
            <form>
              <div className="first_infos" >
                <div className="title_div">
                  <label className="title_label" >Titulo: </label>
                  <input 
                    className="title_input" 
                    type="text" 
                    value={title}
                    maxLength={30}
                    onChange={(event) => this.setState({ title: event.target.value })}
                  />
                </div>
                <div className="category_div">
                  <label className="title_label">Categoria: </label>
                  <select 
                    className="category_selection"  
                    onChange={(event) => this.setState({ topic: event.target.value })}
                    value={topic}
                    >
                    <option value={1}>Saúde</option>
                    <option value={2}>Epistemic</option>
                    <option value={3}>Aurora</option>
                    <option value={4}>Novidades</option>
                  </select>
                </div>
              </div>
            </form>
            
            <div
              id="react_editor_opacity" 
              className="editor_text_div"
            >
              <RichTextEditor
                value={this.state.value}
                onChange={this.onChange}
                placeholder="Escrever aqui ..."
                editorClassName="edit_text"
              />
            </div>

            <div className="Image_loader_div">
              <div>
                <h2>Imagem do card</h2>
                <labe className="title_label">Endereço da imagem: </labe>
                <input 
                  className="image_loader_input" 
                  value={image_url}
                  onChange={(event => this.setState({ image_url: event.target.value }))}  
                />
                <p className="image_advice"> <b> * Imagem do card precisa ser 1920x1080</b> </p>
              </div>
              { edit_post ?
                (<Link 
                  to={`/blog_text/${name_id}/`}
                  className="see_blog_post_on_blog_button"
                  onMouseEnter={() => this.setState({ eye_color: "white" })}
                  onMouseLeave={() => this.setState({ eye_color: "black" })}
                  >
                  <FaRegEye color={eye_color} size={20} />
                  Visualizar
                </Link>)
                : 
                (
                  <div />
                )
              }
            </div>
            
            <div className="show_image_card">
              <img 
                src={image_url}
                width={500} 
                style={{ aspectRatio: 1, borderRadius: 15 }} 
                alt={"Imagem que ficará no card"}
              />
            </div>


          </div>

          <div className="post_details">

            <div className="status_div">
              <labe className="title_label">Status</labe>
              <select 
                onChange={(event) => this.setState({ enable_post: event.target.value })}
                className="status_select"
              >
                <option value={true} >Ativado</option>
                <option value={false} >Desativado</option>
                {/* <option value="Novidades" >Rascunho</option> */}
              </select>
            </div>

            <div className="schedule_div" >
              <labe className="title_label">Como gostaria de agendar esta publicação</labe>
              <select 
                className="status_select"
                onChange={(event) => this.setState({ schedule_type: event.target.value })}
                value={schedule_type.toString()}
              >
                <option value={1}>Com data para publicação</option>
                <option value={2} >Com data para encerrar </option>
                <option value={3} >Com data para laçamento e encerramento </option>
                <option value={4} >Sem agendamento </option>
              </select>
            </div>
            {this.ShowDatepdicker()}
          </div>
        </div>
      </div>
    )
  }

  ShowDatepdicker = () => {
    const { schedule_type, schedule_date, schedule_end_data } = this.state;

    switch (schedule_type.toString()) {
      case "1": return (
        <div className="DatePicker_div"> 
          <label className="title_label">Publicar em: </label>
            <DatePicker 
              ariaHideApp={false}
              onChange={(schedule_date) => this.setState({ schedule_date })}
              value={schedule_date}
              minDate={new Date(moment(moment().format("YYYY-MM-DD")).add("days", 1).format("YYYY-MM-DD"))}
            />
        </div>
      )
      case "2": return (
        <div className="DatePicker_div"> 
          <label className="title_label">Encerrar em: </label>
            <DatePicker 
              ariaHideApp={false}
              onChange={(schedule_end_data) => this.setState({ schedule_end_data })}
              value={schedule_end_data}
              minDate={new Date(moment(moment().format("YYYY-MM-DD")).add("days", 1).format("YYYY-MM-DD"))}
            />
        </div>
      )
      case "3": return (
        <div > 
          <div className="DatePicker_div">
            <label className="title_label">Publicação em: </label>
              <DatePicker 
                ariaHideApp={false}
                onChange={(schedule_date) => this.setState({ schedule_date })}
                value={schedule_date}
                minDate={new Date(moment(moment().format("YYYY-MM-DD")).add("days", 1).format("YYYY-MM-DD"))}
              />
            </div>

            <div className="DatePicker_div">
              <label className="title_label">Encerrar em: </label>
                <DatePicker 
                  ariaHideApp={false}
                  onChange={(schedule_end_data) => this.setState({ schedule_end_data })}
                  value={schedule_end_data}
                  minDate={new Date(moment(moment().format("YYYY-MM-DD")).add("days", 1).format("YYYY-MM-DD"))}
                />
            </div>
        </div>
      )
    }
  }

  SendPost = async (_topic, _image, _author, _html, _title, _post_on, _active) => {
    const { schedule_type, schedule_end_data } = this.state;

    this.setState({ loading: true });

    let schedule_date_end = null;
    let ponst_on = moment().format("YYYY-MM-DD");

    if (schedule_type.toString() === "2" || schedule_type.toString() === "3") {
      schedule_date_end = moment(schedule_end_data).format("YYYY-MM-DD");
    } 

    if (schedule_type.toString() === "1" || schedule_type.toString() === "3") {
      ponst_on = moment(_post_on).format("YYYY-MM-DD");
    }

    try {
      const response = await api.post(payload.endpoints.user, {
        data: {
          route: payload.routes.Send_BlogPost,
          topic: _topic,
          image_url: _image,
          author: _author,
          html: _html,
          title: _title,
          post_on: ponst_on,
          actived: _active,
          today_date: moment().format("YYYY-MM-DD"),
          post_end_on: schedule_date_end,
        }
      });
      
      if (response.status === 200) {
        this.props.history.push("/author_home");
        this.setState({ loading: false });
      } else{
        this.setState({ loading: false });
        window.alert(`Erro: ${response.status}`)
      }

    } catch(e) {
      this.setState({ loading: false });
    }

  }

  getPostDetails = async (_id) => {

    this.setState({ get_info_loading: true });
    const response = await api.get(`${payload.endpoints.user}?action=get_one_new&name_id=${_id}`);

    if (response.status === 200) {
      const { data } = response.data;

      this.setState({ get_info_loading: false });

      this.setState({ 
        title: data.title, 
        topic: data.topic, 
        image_url: data.main_image,
        value: RichTextEditor.createValueFromString(data.news_html, 'html'),
        post_id: data.id,
        name_id: data.name_id,
        blog_text: true
      });

      if (data.post_end_on) {
        this.setState({ 
          schedule_type: 3,
          schedule_date_end: new Date(moment(data.post_end_on).format("YYYY-MM-DD"))
        });
      }

      let posted = moment(data.post_on).format("YYYY-MM-DD");
      const currentDate = moment().format("YYYY-MM-DD");



      if (posted <= currentDate) {
        this.setState({ schedule_bool: false });
      } else {
        posted = moment(posted).add('days', 1);
        posted = new Date (posted);
        this.setState({ schedule_bool: true, schedule_date: posted });
      }
    }
  }

  Delete_Post = async (_id) => {
    const response = await api.delete(payload.endpoints.user, {
      params: {
        route: payload.routes.delete_post,
        id: _id
      }
    })

    if (response.status === 200) {
      this.props.history.push("/author_home");
    } else {
      window.alert(`erro: ${response.status}`);
    }
  }

  UpdatePost = async (_topic, _image, _author, _html, _title, _post_on, _active, _id) => {
    const { schedule_bool, schedule_date_end, schedule_type, schedule_end_data } = this.state;
    
    const currentDate = moment().format("YYYY-MM-DD");

    let date_to_post = "";
    let fix_end_date = null;
    let ponst_on = moment().format("YYYY-MM-DD");

    this.setState({ loading: true });

    if (schedule_type.toString() === "2" || schedule_type.toString() === "3") {
      fix_end_date = moment(schedule_end_data).format("YYYY-MM-DD");
    } 

    if (schedule_type.toString() === "1" || schedule_type.toString() === "3") {
      ponst_on = moment(_post_on).format("YYYY-MM-DD");
    }

    if (schedule_bool.toString() === "true") {
      date_to_post = _post_on;
    } else {
      date_to_post = currentDate;
    }

    const response = await api.put(payload.endpoints.user, {
      data: {
        route: payload.routes.Update_BlogPost,
        title: _title,
        id: _id,
        author: _author,
        topic: _topic,
        image_url:_image,
        html: _html,
        updated_date: currentDate,
        post_on: date_to_post,
        actived: _active,
        post_end_on: fix_end_date,
      }
    });

    if (response.status === 200) {
      this.props.history.push("/author_home");
    } else {
      window.alert(`Erro: ${response.status}`);
    }
    this.setState({ loading: false });
  }

  validadeValues() {

    const { infos } = this.props.reducer.author_infos;
    const { 
      topic, 
      image_url,
      title,
      schedule_date,
      enable_post,
      schedule_bool,
      blog_text,
      edit_post,
      post_id
    } = this.state;


      if (title !== "" && image_url !== "" && blog_text) {
        if (schedule_bool) {
          if (schedule_date !== "") {
            //postar uma data de publicação

            if(edit_post) {
              return this.UpdatePost(
                topic, 
                image_url,
                infos.username,
                this.state.value.toString('html'),
                title,
                schedule_date,
                enable_post,
                post_id
              );
            }
            return this.SendPost(
              topic, 
              image_url,
              infos.username,
              this.state.value.toString('html'),
              title,
              schedule_date,
              enable_post
            );
          } else {
            window.alert("Insira uma data para a publicação");
          } 
        } else {
          //postar sem uma data de publicação

          if(edit_post) {
            return this.UpdatePost(
              topic, 
              image_url,
              infos.username,
              this.state.value.toString('html'),
              title,
              schedule_date,
              enable_post,
              post_id
            );
          }
          return this.SendPost(
            topic, 
            image_url,
            infos.username,
            this.state.value.toString('html'),
            title,
            schedule_date,
            enable_post
          );
        }
      } else {
        window.alert("Preencha todos os campos para fazer um post");
      }
  }
}

const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({SaveAuthorInfosAction}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(New_post);