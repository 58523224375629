import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Container, Content, BlogPostText, HideBlogPostText, HideBlogPostButton } from './styles';
import { FaArrowAltCircleUp } from 'react-icons/fa';

import BlogInfo from '../BlogInfo';
import BlogImage from '../BlogImage';
import webconsole from '../../config/degub';

export default function Section({ title, image, date, tags, introduction, color, video, text, onClickTag, fromLink, autofocus }) {
  
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    checkIfIsFronLink();
    // focusDiv();
    // document.getElementById('tESTE_DE_ID').focus()
  });

  function focusDiv() {
    // webconsole.log();
  }

  function checkIfIsFronLink() {
    if(!expand && fromLink){
      setExpand(true);
    }
  }

  return (
    <Container
      autofocus={autofocus}
      expand={expand}
    >
      <HideBlogPostText>
        <HideBlogPostButton 
          expand={fromLink ? false : expand }
          onClick={() => {
            setExpand(false);
          }}
        >
          <FaArrowAltCircleUp size={50} color={color} />
        </HideBlogPostButton>
      </HideBlogPostText>
      
      <Content
        expand={expand}
        onClick={() => {
          setExpand(true);
        }}
      >
        <BlogImage 
          image={image} 
          video={video}   
          expand={expand}  
        />

        <BlogInfo  
          color={color}
          title={title}
          date={date}
          tags={tags}
          introduction={introduction}
          onClickTag={onClickTag}
        />
      </Content>

      <BlogPostText
        expand={expand}
        dangerouslySetInnerHTML={{ __html: text }}
      />

    </Container>
  )
}