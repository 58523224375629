import React, { Component } from 'react';

import Brazil_flag from "../../img/bandeira-do-brasil.png";
import US_flag from "../../img/bandeira-dos-US.png";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChangeLanguageAction, ChangeLanguageFeedbackAction } from '../../actions';

import "./styles.css";

class Country_Selector extends Component {
  constructor (props) {
    super(props);
    this.state = { 
      selected_flag: Brazil_flag
    };
  }
  render(){
    const { selected_flag} = this.state;
    const { show } = this.props.reducer.show_language_feedback;
    return(
      <div className="Main-Button-Div">
        <button className="Button-selected-flag" onClick={() => {
          if(show) {
            document.querySelector(".list-options").setAttribute("style", "animation: diminuindo 0.5s");
            setTimeout(() => {
              this.props.ChangeLanguageFeedbackAction(false);
            }, 400)
          } else {
            this.props.ChangeLanguageFeedbackAction(true);
          }
        }}>
          <img src={selected_flag} className="img" />
        </button>
        {this.CountryList(show)}
        {/* Esse h5 serve apenas para consertar um bug no safari
          Se deletar essa linha por algum motivo
          quando clicar na bandeira ela irá desaparecer quando a
          animação terminar de ser feita
        */}
        <h5 style={{ color: "transparent" }} >X</h5>
      </div>
    )
  }
  CountryList(_show){
    if(_show) {
      return (
        <ul className="list-options">
          <div className="pointer-div" />
          <li className="flag-list-li">
            <button 
              className="button-list-option" 
              onClick={() => {
                document.querySelector(".list-options").setAttribute("style", "animation: diminuindo 0.5s");
                this.props.ChangeLanguageAction("pt_BR");
                this.setState({ selected_flag: Brazil_flag });
                setTimeout(() => {
                  this.setState({ selected_flag: Brazil_flag });
                  this.props.ChangeLanguageFeedbackAction(false);
                }, 400)
              }}
            >
              <img src={Brazil_flag} className="img" />
            </button>
          </li>
          <li className="flag-list-li">
            <button 
              className="button-list-option" 
              onClick={() => {
                document.querySelector(".list-options").setAttribute("style", "animation: diminuindo 0.5s");
                this.props.ChangeLanguageAction("en_US");
                this.setState({ selected_flag: US_flag });
                setTimeout(() => {
                  this.props.ChangeLanguageFeedbackAction(false);
                }, 400)
              }}
            >
              <img src={US_flag} className="img" />
            </button>
          </li>
        </ul>
      )
    }
  }
}

const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ChangeLanguageAction, ChangeLanguageFeedbackAction}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Country_Selector);