import React, { useEffect, useState, useMemo } from 'react';
import { Container, 
  LoginCard, 
  EmailInput, 
  PassworInput, 
  ButtonLogin, 
  Title, 
  Logo, 
  ForgotPassword, 
  BackgroundImage 
} from './styles';
import epistemic_logo from '../../img/EPISTEMIC AZUL_Círculo_média resolução.png';
import api from '../../services/api';
import { payload } from '../../services/payload';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SaveAuthorInfosAction, ChangeHeaderColorAction } from '../../actions';
import Blog_background from '../../img/Blog-background.png';

let updateHeaderColor = 1;
const Author_Login = (props) => {

  useEffect(() => {  
    //Esse if está servindo para parar de atualizar a todo momento está tela
    //Se nao fizer isso a tela fica muito lenta
    if(updateHeaderColor === 1) {
      props.ChangeHeaderColorAction(6);
      updateHeaderColor = 2;
    }
  }, [props]);

  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [logged, SetLogged] = useState(false);
  const [loading, SetLoading] = useState(false);

  if(logged) {
    return(
      <Redirect to="/author_home"/>
    )
  }
  return(
    <Container>
      <BackgroundImage background_image={Blog_background} />

      <LoginCard>
        <Logo src={epistemic_logo} />
        
        <Title>
          Fazer login {logged.toString()}
        </Title>
        

        <EmailInput 
          value={email}
          onChange={(input) => SetEmail(input.target.value)}
        />

        <PassworInput 
          type="password" 
          value={password}
          onChange={(input) => SetPassword(input.target.value)}
        />
      
        <ButtonLogin 
          type="submit" 
          onClick={(event) => Do_Login(event)}
        >
          Login
        </ButtonLogin>

        <ForgotPassword>
          Esquceu sua senha ? <b>Clique aqui</b>
        </ForgotPassword>
      
      </LoginCard>

    </Container>
  )

  async function Do_Login(e) {
    //É preciso fazer isso para que o botão não de um refresh na página
    e.preventDefault();

    try {
      if (password !== "" && email !== "") {

          SetLoading(true);
          const response = await api.post(payload.endpoints.user, {
            data: {
              route: payload.routes.login,
              email: email,
              password: password
            }
          });

          if(response.status === 200){
            props.SaveAuthorInfosAction(response.data.data, true);
            props.ChangeHeaderColorAction(9);

            SetLoading(false);
            SetLogged(true);
            return;

          } else {
            SetLoading(false);
            return window.alert(`Ocorreu algum erro ${response.status}`);
          }
      } else {
        return window.alert("preencha todos os campos");
      }
    } catch (e) {
      SetLoading(false);
      return window.alert("Ocorreu algum erro");
    }
  } 
}

const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({SaveAuthorInfosAction, ChangeHeaderColorAction}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Author_Login);