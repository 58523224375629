import React, { Component, useState } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  ChangeLanguageAction, 
  ChangeHeaderColorAction, 
  FooterColorAction,
  ChangeLanguageFeedbackAction  
} from '../../actions';
import { CountryDropdown } from 'react-country-region-selector';
import Footer from '../../components/Footer';
import api from '../../services/api';
import { payload } from '../../services/payload';
import ReactGA from 'react-ga';

import "./styles.css";

class Contact extends Component {

  constructor (props) {
    super(props);
    this.state = { 
      subject: 1,
      user_message: "",
      user_name: "",
      user_email: "",
      user_phone: "",
      country: "Brazil",
      showFeedback: false,
      message_code: 406,
      loading: false
    };
  }
  componentDidMount() {
    const { language } = this.props.reducer.Language;

    window.scrollTo(0, 0);
    this.props.FooterColorAction(false);
    this.props.ChangeHeaderColorAction(8);

    this.setState({  
      subject: language.about_page.options.info,
      country: language.language === "pt_BR"? "Brazil": "United States"
    });


    ReactGA.pageview("/contact");

  }
  
  showLoading() {
    const { language } = this.props.reducer.Language;
    const { 
      country, 
      user_message, 
      user_name, 
      user_email, 
      user_phone,
      loading,
      showFeedback,
      subject
    } = this.state;

    if (loading) {
      return (
        <div className="submit-div-button" >
          <button className="input_submit">
            <i className="fa fa-circle-o-notch fa-spin"></i>Loading
          </button>
        </div>
      )
    }

    return (
      <div className="submit-div-button" >
        <button onClick={() => {
          document.getElementById('myfocusablediv').focus();
          this.setState({ loading: true });
          subject === language.about_page.options.delete_account 
            ? this.setState( { user_message: "delete_account", user_name: user_phone })
            : this.setState( { user_message: user_message });

          if (user_name && user_phone && user_message && user_email) { 
            api.post(payload.endpoints.send_feedback, {
              data: {
                  message: user_message,
                  user_name: user_name,
                  user_email: user_email,
                  cell_phonenumber: user_phone,
                  user_county: country,
                  subject: subject,
                  company: "epistemic"
              }
            })
            .then(res => {
              this.setState({ 
                loading: false, 
                user_message: "", 
                user_name: "", 
                user_phone: "", 
                user_email: "",
                message_code: 200
              });
              this.hideFeedback(showFeedback);

            })
            .catch(err => {
              this.setState({ loading: false, message_code: 502 });
              this.hideFeedback(showFeedback);

          });
          
          } else {
            this.setState({ loading: false, message_code: 406 });
            this.hideFeedback(showFeedback);
          }
            }} className="input_submit">
          {language.about_page.send}
        </button>
      </div>
    )
  }

  hideFeedback(_show) {
    if (!_show){
      this.setState({ showFeedback: true });
      setTimeout(() => {
        document.querySelector(".sendFeedback-div").setAttribute("style", "animation: hide_feedback 0.5s");
        setTimeout(() => {
          this.setState({ showFeedback: false });
        }, 400);
      }, 2000);
    }
  }

  SendFeedback(_show, _error) {
    const { language } = this.props.reducer.Language;
    let color = "red";
    let message = "Error";

    switch (_error) {
      case 406: color = "#ff6c68"; message = language.about_page.about_alert.empty; break;
      case 200: color = "#88db97"; message = language.about_page.about_alert.sucess; break;
      case 502: color = "#ff6c68"; message = language.about_page.about_alert.fail; break;
    }

    if(_show){
      return (
        <div id="Feedback-div" className="sendFeedback-div" >
          <div style={{ 
              backgroundColor: color,
              width: "50%", 
              height: "100%", 
              borderRadius: 20, 
              color: "white", 
              justifyContent: "center", 
              alignItems: "center", 
              display: "flex",
              fontWeight: "bold",
            }} >
            {message}
          </div>
        </div>
      );
    }
  }

  render() {
    const { language } = this.props.reducer.Language;
    const { 
      subject, 
      user_message, 
      user_name, 
      user_email, 
      user_phone, 
      country,
      showFeedback,
      message_code
    } = this.state;

    // Check if the selected option is "delete_account"
    const isDeleteAccountSelected = subject === language.about_page.options.delete_account;

    return (
      <div style={{ background: "white" }}>
        <div className="main-apresentation">
          <img 
            src={"https://epitemic-website-images.s3-sa-east-1.amazonaws.com/Contato/Contato.png"} 
            loading="lazy"
             alt="Epistemic-Main-Image" 
            className="main_image_contact_page" 
          />
          <h1 
            className="title-contact" >
              {language.contact_page.talk} <br /> 
              {language.contact_page.us} <br />
              {language.contact_page.when} <br />
              {language.contact_page.wish} <br />
          </h1>
        </div>
        <div className="subtitle-email-contact">
          <h2 style={{ textAlign: 'center'}}>
            contato@epistemic.com.br
          </h2>
        </div>
        <div className="Div-Footer">
          <Footer black={false} />
        </div>
      </div>
    );
  }          
}


const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    ChangeLanguageAction, 
    ChangeHeaderColorAction, 
    FooterColorAction,
    ChangeLanguageFeedbackAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Contact);