import { LanguageReducer } from './language';
import { Header_Color_Reducer } from './headerColor';
import { Footer_Color_Reducer } from './footerColor';
import { Aurora_Footer_Color_Reducer} from './auroraFooterColor';
import { Language_Feed_Back_Reducer } from './LanguageFeedBack';
import { AuthorInfosReducer } from './authorUserInfos';
import { combineReducers } from 'redux';

export const Reducers = combineReducers({
  Language: LanguageReducer,
  index: Header_Color_Reducer,
  footer_color: Footer_Color_Reducer,
  aurora_footer: Aurora_Footer_Color_Reducer,
  show_language_feedback: Language_Feed_Back_Reducer,
  author_infos: AuthorInfosReducer
})