import React from 'react';
import "./styles.css";
import { Link } from 'react-router-dom';

const HeaderButton = (info) => (
  <div style={{marginLeft: 20 }}>
    <div className="background-button"
      style={{ backgroundColor: info.feedback ? '#DF7400' : "transparent"  }} 
    >
      {!info.doctors ? (<Link 
        className="Header-button-name" 
        to={info.page} 
        style={{ color: changeColor(info.section) }} 
        onClick={info.onClick}
      > 
        {info.title}
      </Link>) : (
        <a 
          className="Header-button-name" 
          href={`https://www.epistemic.com.br/physicians_home/`}
          // href={`http://localhost:3001/welcome/${info.language}`}
          style={{ color: changeColor(info.section) }} 
        >
          {info.title}
        </a>
      )}
    </div>
    <div style={{
      marginRight: 10,
      marginLeft: 10,
      display: 'flex',
      height: 5,
      background: info.feedback ? '#DF7400' : 'transparent',
      marginTop: 10,
      borderRadius: 20,
    }} className="div-Header-Feedback"></div>
  </div>
)

function changeColor (_section) {
  if (_section === 5 || _section === 7 || _section === 8 || _section === 9 ) {
    return "black";
  }
  return "white"
}

export default HeaderButton;