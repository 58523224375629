import { CHANGE_HEADER_COLOR } from '../actions/actionsTypes';

const initialState = {
  index: 1
};

export const Header_Color_Reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_HEADER_COLOR:
      return {
        ...state,
        index: action.index
      }

    default:
      return state;
  }
}