import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  ChangeLanguageAction, 
  FooterColorAction, 
  ChangeHeaderColorAction, 
  ChangeLanguageFeedbackAction 
} from '../../actions';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Parallax } from 'react-scroll-parallax';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./styles.css";

class Research extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.FooterColorAction(false);
    this.props.ChangeHeaderColorAction(1);

    ReactGA.pageview("/home");

  }
  constructor(props) {
    super(props);
    this.state = { 
    };
  }
  render() { 
    const { show } = this.props.reducer.show_language_feedback;
    const { language } = this.props.reducer.Language;
    const { 
    } = this.state;

    return (
      <div className="Main-Page"
        onClick={() => {
          try {
            if (show) {
              document.querySelector(".list-options").setAttribute("style", "animation: diminuindo 0.5s");
              setTimeout(() => {
                this.props.ChangeLanguageFeedbackAction(false);
              }, 400)
            }
          }catch(e) {
            console.log(e);
          }
        }}
        onTouchStart={() => {
        document.querySelector(".side_bar").setAttribute("style", "left: -50%;display: grid; animation-name: closeSisde_bar;");

        try {
          if (show) {
            document.querySelector(".list-options").setAttribute("style", "animation: diminuindo 0.5s");
            setTimeout(() => {
              this.props.ChangeLanguageFeedbackAction(false);
            }, 400)
          }
        }catch(e) {
          console.log(e);
        }
      }}>
        <Helmet>
          <title>Epistemic - {language.header.home.toLowerCase()}</title>
        </Helmet>
        <div className="main_image-div">
          <div className="Div-gradient" >
            <h2 className="Second-text-title" >
              {language.research.header}
            </h2>
            <p className="Second-text-explanation" >
              {language.research.explainer} <b> {language.research.bold} </b>
            </p>
            <div className="Research-Form">
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSd7TqgaB8VHWR2tY8zCjqn1hD2BHtRKFV2HEdTuuo6Azo01rw/viewform?embedded=true"
                width="100%"
                height="700"
                frameborder="0"
                marginheight="0"
                marginwidth="0"
              >
                Loading…
              </iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    ChangeLanguageAction, 
    FooterColorAction, 
    ChangeHeaderColorAction, 
    ChangeLanguageFeedbackAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Research);