import React, { Component } from 'react';
import "./styles.css";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { 
  ChangeLanguageAction, 
  FooterColorAction, 
  ChangeHeaderColorAction, 
  ChangeLanguageFeedbackAction 
} from '../../actions';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { Parallax } from 'react-scroll-parallax';

import Footer from '../../components/Footer';

import GooglePLayBadge_pt_BR from "../../img/pt_BR/google-play-badge.png";
import GooglePLayBadge_en_US from "../../img/en_US/google-play-badge (1).png";


class App extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.FooterColorAction(true);
    this.props.ChangeHeaderColorAction(2);

    ReactGA.pageview("/app");
  }
  constructor(props) {
    super(props);
    this.state = { 
      App_main_url: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+app/Epistemic-App-Inicial.png",
      App_share_url: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+app/Epistemic-App-Share.png",
      App_indicators_url: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+app/Epistemic-App-Indicators.png",
      App_help_Center_url: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+app/Epistemic-App-Help-Center.png",
      App_premium_url:"https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+app/Epistemic-App-Premium.png",
      App_normal_url: "https://epitemic-website-images.s3-sa-east-1.amazonaws.com/P%C3%A1gina+app/Epistemic-App-Normal.png"
    };
  }
  render(){
    const { language } = this.props.reducer.Language;
    const { show } = this.props.reducer.show_language_feedback;
    const { 
      App_main_url,
      App_share_url,
      App_indicators_url,
      App_help_Center_url,
      App_premium_url,
      App_normal_url
    } = this.state;
    return(
      <div className="Main-App" 
        onClick={() => {
          try {
            if (show) {
              document.querySelector(".list-options").setAttribute("style", "animation: diminuindo 0.5s");
              setTimeout(() => {
                this.props.ChangeLanguageFeedbackAction(false);
              }, 400)
            }
          }catch(e) {
            console.log(e);
          }
        }}
      >
      <Helmet>
        <title>Epistemic - {language.header.app.toLowerCase()}</title>
      </Helmet>
        <div className="Main-Image" >
          <div style={{width: "50%"}} >
            <Parallax className="custom-class" y={[-10, 2]} tagOuter="figure">
              <img src={App_main_url} loading='lazy' alt="App_Image" className="Epistemic_App_image" />
            </Parallax>
          </div>
          <div className="Epistemic-Main-Text">
            <p className="Epistemic_title">{language.app_page.epistemic_app_title}</p>
            <p className="Epistemic_subtitle">{language.app_page.epistemic_app_phrase}</p>
            <p className="Epistemic_explanation">{language.app_page.epistemic_app_explanation}</p>
            <div className="Google_Play_Badge_top_div">
              <a href="https://play.google.com/store/apps/details?id=br.com.epistemic" target="_blank" className="Google_Play_Badge_Button" >
                <img className="Google_Play_Badge_top_img" src={language.language === "pt_BR" ? GooglePLayBadge_pt_BR : GooglePLayBadge_en_US } alt={"Google Play"} />
              </a>
            </div>
          </div>
        </div>

        <div className="Fix-bug-footer" >
          <div className="Main-text-Share-div" >
            <p className="Indicators-title" >{language.app_page.create_share_title}</p>
            <p className="Indicators-eplanation">{language.app_page.create_share_explanation_1}</p>
            <p className="Indicators-eplanation">{language.app_page.create_share_explanation_2}</p>
            <p className="Indicators-eplanation">{language.app_page.create_share_explanation_3}</p>
            <p className="Indicators-eplanation">{language.app_page.create_share_explanation_4}</p>
          </div>
          <div className="Epistemic_Share_div">
            <img className="Epistemic_Share" src={App_share_url} alt={"App_share"} />
          </div>
          
          <div style={{backgroundColor :"black", marginTop: 50}} >
            <div className="Main-text-Indicators-div">
              <p className="Indicators-title" >{language.app_page.indicators_title}</p>
              <p className="Indicators-eplanation">{language.app_page.indicators_explanation}</p>
            </div>
            <div className="Epistemic_Indicators_div" >
              <img className="Epistemic_Indicators" src={App_indicators_url} alt={"App_indicators"} />
            </div>
          </div>

          <div className="Epistemic-Seizure_and_Help_div">
            <div className="Epistemic-Seizure_and_Help_Texts" >
              <div className="Epistemic-Help-Center-div" >
                <p className="Indicators-title-Help" >{language.app_page.help_center_title}</p>
                <p className="Indicators-eplanation-Help">{language.app_page.help_center_explanation}</p>
              </div>
              <div className="Indicators-eplanation-div" >
                <p className="Indicators-title-Help" >{language.app_page.seizures_title}</p>
                <p className="Indicators-eplanation-Help">{language.app_page.seizures_explanation_1}</p>
                <p className="Indicators-eplanation-Help">{language.app_page.seizures_explanation_2}</p>
                <p className="Indicators-eplanation-Help">{language.app_page.seizures_explanation_3}</p>
              </div>
            </div>
            <img className="Epistemic_Help_Center" src={App_help_Center_url} alt={"App_help_Center"} />
          </div>



          <div className="Epistemic-Premium">
            <div className="Indicators-Premium-div" >
              <div className="Indicators-text-div" >
                <p className="Indicators-title-Premium" >{language.app_page.epistemic_pro_title}</p>
                <p className="Indicators-eplanation-Premium">{language.app_page.epistemic_pro_explanation_1}</p>
                <p className="Indicators-eplanation-Premium">{language.app_page.epistemic_pro_explanation_2}</p>
              </div>
              <img className="Epistemic_Premium_img" src={App_premium_url} alt={"App_Premium"} />
            </div>
          </div>

          <div className="Epistemic-Normal">
            <img className="Epistemic_Normal_img" src={App_normal_url} alt={"App_Premium"} />
              <div className="Epistemic_Normal_Div" >
                <p className="Epistemic-title-Normal" >{language.app_page.download_title}</p>
                <p className="Epistemic-eplanation-Normal">{language.app_page.download_explanation}</p>
                <div className="Google_Play_Badge_bottom_div">
                  <a href="https://play.google.com/store/apps/details?id=br.com.epistemic" target="_blank" className="Google_Play_Badge_Button" >
                    <img className="Google_Play_Badge_bottom_img" src={language.language === "pt_BR" ? GooglePLayBadge_pt_BR : GooglePLayBadge_en_US } alt={"Google Play"} />
                  </a>
                </div>
              </div>
          </div>
        <div className="Footer_div" >
          <Footer black={true} />
        </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = store => ({
  reducer: store
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ 
    ChangeLanguageAction, 
    FooterColorAction, 
    ChangeHeaderColorAction, 
    ChangeLanguageFeedbackAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);