import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
`;

export const MainEpistemicBlogImage = styled.img`
  width: 32%;
  aspect-ratio: 1;
`;

export const MainEpistemicBlogText =styled.h1 `
  font-size: 60px;
  font-family: "axis";
  color: white;
`