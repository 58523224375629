import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  display: flex;
  /* padding: 50px 10px 10px 20px; */
  background-color: transparent;
  align-items: center;
  flex-direction: column;
`;

export const Background_Image = styled.img`
  width: 100%;
  aspct-ratio: 1;
  /* height: 1000px; */
  position: absolute;
  margin-top: -150px;
  z-index: -1;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 20px;
`;

export const ShowErrorMessage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;

`

export const ErrorMessage = styled.h1`
  font-size: 20px;
  color: orange;
  margin-top: 10px;
  text-align: center;
`

export const FotterDiv = styled.div`
  width: 100%;
  padding-top: 50px;
`