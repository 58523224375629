import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  background-color: white;
`;

export const LoadingMainDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
`


export const TitleInput = styled.input`
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border-style: none;
  padding: 0px 5px 0px 5px;
  font-size: 18px;
  font-family: 'axis';
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
`

export const FirtstInfosDiv = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 40px 20px 20px;
`

export const SecondInfosDiv = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const TagButton = styled.button`
  padding: 3px;
  border-style: solid;
  border-color: ${props => props.color ? props.color : "black"};
  border-radius: 5px;
  background-color: ${props => props.feedback? props.color: "transparent"};
  color: ${props => props.feedback? "white": "black"};
  font-weight: bold;
  font-family: "axis";

  &:hover {
    cursor: pointer;
  }
`

export const TagButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const PostOptions = styled.div`
  display: flex;
  padding: 20px 10px 10px 10px;
  width: 100%;
  flex-direction: column;
  border-style: none;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 3em rgba(0, 0, 0, 0.5);
  background-color: white;
`

export const PostSection = styled.section`
  width: 90%;
  padding: 0px 0px 20px 0px
`

export const PostOptionTitle = styled.h2`
  margin-bottom: 10px;
  font-size: 20px;
  font-family: "axis";
`

export const PostDateInput = styled.input`
  margin-bottom: 10px;
  width: 50%;
  border-radius: 5px;
  border-style: solid;
  font-family: 'arial';
  padding: 5px;
`

export const CheckBoxEndPost = styled.input`
  border-radius: 20px;
`

export const CheckBoxPostDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CheckBoxEndPostLegend = styled.p`
  margin-left: 10px;
  font-size: 15px;
`

export const PostImageInput = styled.input`
  width: 100%;
  height: 30px;
  border-radius: 10px;
  border-style: solid;
  border-color: black;
  padding: 0px 5px 0px 5px;
  font-size: 15px;
`

export const PostMainColor = styled.input`

`

export const ImagePreview = styled.img`
  width: 100%;
  height: 200px;
  margin-top: 10px;
  border-radius: 10px;
`
export const EditorDiv = styled.div`
  width: 100%;
  height: 900px;
  padding: 20px 0px 0px 0px;
`

export const ButtonOptions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px 20px 20px;
`
export const ActionButton = styled.div`
  padding: 5px;
  font-family: "axis";
  border-style: none;
  border-radius: 5px;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);

  &:hover {
    background-color: ${props => props.color};
    cursor: pointer;
    color: white;
  }
`